import {IHora} from '../models/IHora'

const maxToReserve = (date, typeMaxToReserve, valueMaxToReserve, dateMaxToReserve) => {
  let today = new Date()
  today.setHours(0, 0, 0, 0)
  let dateToMax
  // se puede dar los siguientes casos:
  // - typeMaxToReserve === byDate y dateMaxToReserve === una fecha
  // - typeMaxToReserve === byDays y valueMaxToReserver con valor
  // - typeMaxToReserve === byMonth y valueMaxToReserver con valor
  // resto no es necesario controlar

  if (typeMaxToReserve === 'byDate') {
    if (dateMaxToReserve) {
      dateToMax = new Date(dateMaxToReserve)
    }
  } else {
    if (typeMaxToReserve === 'byDays' && valueMaxToReserve) {
      dateToMax = new Date(today.setDate(today.getDate() + valueMaxToReserve))
    } else if (typeMaxToReserve === 'byMonth' && valueMaxToReserve) {
      dateToMax = new Date(today.setMonth(today.getMonth() + valueMaxToReserve))
    }
  }

  if (dateToMax) {
    dateToMax.setHours(0, 0, 0, 0)

    if (date >= dateToMax) {
      return true
    }
  }
  return false
}

const maxBeforeReserve = (date: Date, typeMaxBeforeReserve, valueMaxBeforeReserve, isTramoHora, selectedHour: IHora) => {
  if (!valueMaxBeforeReserve) {
    return false
  }

  let today = new Date()
  const _date = new Date(date)
  if (!isTramoHora) {
    _date.setHours(0, 0, 0, 0)
  } else {
    if (!selectedHour && isTramoHora) {
      return true
    }
    _date.setHours(selectedHour.hours, selectedHour.minutes)
  }

  let dateBeforeMax: Date // fecha mínima para poder reservar
  dateBeforeMax = new Date(today)
  if (typeMaxBeforeReserve === 'byHours') {
    if (!isTramoHora) {
      dateBeforeMax.setHours(today.getHours() + valueMaxBeforeReserve)
    } else {
      dateBeforeMax.setHours(today.getHours() + valueMaxBeforeReserve)
    }
    // } else if (typeMaxBeforeReserve === 'byDays') {

  } else {
    if (!isTramoHora) {
      today.setHours(0, 0, 0, 0)
    } else {
      today.setHours(selectedHour.hours, selectedHour.minutes, 0, 0)
    }
    dateBeforeMax.setDate(today.getDate() + valueMaxBeforeReserve)
  }

  return _date.getTime() <= dateBeforeMax.getTime()
}

export const DisabledDay = {
  maxToReserve,
  maxBeforeReserve
}

export const getHoraIdaHigher = (stretch): IHora => {
  if (!stretch.tramoHora) {
    return {
      hours: 0,
      minutes: 0
    }
  }
  return stretch.horasIda?.reduce((previousValue, currentValue) => {
    if (!previousValue) {
      return currentValue
    }
    if (currentValue.hours > previousValue.hours) {
      return currentValue
    }
    if (currentValue.minutes > previousValue.minutes) {
      return currentValue
    }
    return previousValue
  })
}