import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import Payment from "payment";
import {useInitIdioma} from '../customHooks/useInitIdioma'

export const PaymentForm = (props) => {

  const {card_info, setCardInfo} = props

  const {t} = useInitIdioma()
  
  const handleInputFocus = (e) => {
    setCardInfo({ ...card_info, focus: e.target.name });
  }
  
  const handleInputChange = ({target}) => {
    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value);
    }

    setCardInfo({ ...card_info, [target.name]: target.value });
  }
  
    return (
      <div id="PaymentForm">
        <div style={{display: 'grid', justifyContent: 'center', marginBottom: '15px'}}>
            <h3 style={{fontSize: '25px'}}>{t('paymentDetails')?.toUpperCase()}</h3>
        </div>
        <Cards
          cvc={card_info.cvc}
          expiry={card_info.expiry}
          focused={card_info.focus}
          name={card_info.name}
          number={card_info.number}
        />
        <form style={{marginTop: '25px', width: '100%'}}>
          <input
            className="form-control mb-1"
            type="tel"
            name="number"
            placeholder="Card Number"
            pattern="[\d| ]{16,22}"
            required
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
          <input
            className="form-control mb-1"
            type="text"
            name="name"
            placeholder="Name"
            required
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
          <input
            className="form-control mb-1"
            type="tel"
            name="expiry"
            placeholder="Valid Thru"
            pattern="\d\d/\d\d"
            required
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
          <input
            className="form-control"
            type="tel"
            name="cvc"
            placeholder="CVC"
            pattern="\d{3,4}"
            required
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
          
        </form>
      </div>
    );
  }

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }
  // @ts-ignore
  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`
  }

  return clearValue;
}

export function formatFormData(data) {
  return Object.keys(data).map(d => `${d}: ${data[d]}`);
}

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export function formatCVC(value, allValues = {}) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  // @ts-ignore
  if (allValues.number) {
    // @ts-ignore
    const issuer = Payment.fns.cardType(allValues.number);
    maxLength = issuer === "amex" ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}