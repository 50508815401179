export const conditionalAdd = (busqueda, list, item) => {
    
    if(busqueda.trim() !== ''){
        if(item.name.toLowerCase().includes(busqueda.trim().toLowerCase())){
            list = [...list,item]
        } 
    }else{
        list = [...list,item]
    }  
    return list
}

export const isValidWithChannels = (canales, s) => {
    let flag = false;
    canales.map((c) => {
        if(c.currencies === s.primaryMoney.alphabeticCode){
            flag = true;
        }
        return null
    })
    return flag
}