export default function handlerError(type, t) {
    let message = '';

    if(type === 'Bad Track Data'){
       // message = 'La pasarela de pago nos indica que el CVV es inválido o la fecha de vencimiento está expirada.';
        message = `${t("cvvinvalidmsg")}`
    }
    else if(type === 'Invalid Card Number')
       // message = 'La pasarela de pago nos indica que el número de tarjeta es inválido.';
       message = `${t("invalidcardnumbermsg")}`
    else if(type === 'Request invalid: Card expiry month invalid') 
       // message = 'La pasarela de pago nos indica que la fecha de vencimiento es inválida.';
       message = `${t("invalidcardexpirymsg")}`
    else if(type === 'Invalid request') 
       // message = 'La pasarela de pago nos indica que la petición tiene datos no válidos. Channel_id.';
       message = `${t("invalidrequestmsg")}`
    else if(type === 'Session has expired')
        //message = 'La pasarela de pago nos indica que la sesión ha expirado. Token inválido.';   
        message = `${t("sessionexpiredmsg")}`   
    else if(type === 'Token has expired')    
            //message = 'La pasarela de pago nos indica que la sesión ha expirado. Token inválido.';   
            message = `${t("tokenexpiredmsg")}`     
    else 
        //message = `Hemos obtenido un error inesperado: ${type}.`;
        message = `${t("unexpectederrormsg")}: ${type}.`  

  return message;
}
