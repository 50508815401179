import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useHistory } from 'react-router-dom';
import { FooterView } from '../../components/FooterView';
import { isValidGreenpayOrder, modalError, modalWarning, resultGreenpayTicando, getOrderMultiSafePay, resultStripe, resultCredomatic} from 'Modules/Payment/Request';
import { IPasarelaPago } from 'models/PasarelaPago';
import * as Sentry from "@sentry/react";
import Swal from 'sweetalert2';
import { useInitIdioma } from 'customHooks/useInitIdioma';
import { red } from '@material-ui/core/colors';


interface Props {
    public?: string
    response?:string
    responsetext?: string
    uuid?: any
}

type ProcesandoPagoProps = RouteComponentProps<Props>;

export const ProcesandoPagoCredomatic: React.FC<ProcesandoPagoProps> = (props: ProcesandoPagoProps) => {

    const publicToken = props.match.params.public;
    const history = useHistory();
    let uuid = props.match.params.uuid;
    let response = new URLSearchParams(props.location.search).get("response")
    const responsetext = new URLSearchParams(props.location.search).get("responsetext")
    const orderid = new URLSearchParams(props.location.search).get("orderid")
    const amount = new URLSearchParams(props.location.search).get("amount")
    const transactionid = new URLSearchParams(props.location.search).get("transactionid")
    const avsresponse = new URLSearchParams(props.location.search).get("avsresponse")
    const cvvresponse = new URLSearchParams(props.location.search).get("cvvresponse")
    const time = new URLSearchParams(props.location.search).get("time")
    const hash = new URLSearchParams(props.location.search).get("hash")

    const name = new URLSearchParams(props.location.search).get("name")
    const key_id = new URLSearchParams(props.location.search).get("key_id")
    const surname = new URLSearchParams(props.location.search).get("surname")
    const email = new URLSearchParams(props.location.search).get("email")
    const telephone = new URLSearchParams(props.location.search).get("telephone")
    const additionalInformation = new URLSearchParams(props.location.search).get("additionalInformation")
    const client = new URLSearchParams(props.location.search).get("client")

    const {idioma, t, i18n} = useInitIdioma(publicToken) // Custom Hook

    const urlBasePost = `${process.env.REACT_APP_baseURLTicando}/paymentcart/result?public=${publicToken}&cartToken=${uuid}`
    const CREDOMATIC: IPasarelaPago = {
        id: 6, name: 'CREDOMATIC'
    }
    useEffect(() => { 
        //response = "1"
        if(response === "1") {// Pago correcto // TODO if(response === "1") { //
            let url_post = `${urlBasePost}&pasarela=${CREDOMATIC.name}`
            //const result = fetch()
            //const key_id = "14127545"
            const dataBooking = {
                name,
                surname,
                email, 
                telephone,
                additionalInformation
            }
            const additional_info = {
                //cart: cart, // Para prueba de multisafepay se ha quitado
                url: `https://ticket.ticando.net/?client=${client}&numero=${orderid}`,
                uuid: orderid, 
                //tokenTicket,
               // description,
                dataBooking
            };
            resultCredomatic(orderid, amount, response, transactionid, avsresponse, cvvresponse, time, key_id, additional_info, hash, url_post).then(res => {
                let code = res.code
                switch(code) {
                    case 200: 
                                history.push(`/${publicToken}/payment/success`) 
                                break;
                    case 482:   
                                history.push(`/${publicToken}/payment/pendingValidation/${code}`)
                                break;
                    case 501:
                                history.push(`/${publicToken}/payment/pendingValidation/${code}`)
                                break;
                    case 502:   
                                history.push(`/${publicToken}/payment/pendingValidation/${code}`)
                                break;
                    default:
                                history.push(`/${publicToken}/payment/pendingValidation/500`)
                }       
            })
       } else if(response === "2" || response === "3") {
            modalError(`${t("errorduringPayment")}: ${responsetext}`,
            history.push(`/${publicToken}/cart`))
       }               
    },[])

    return(
        <div className="page-wrapper animated fadeIn">
            <header className="book-embed-nav with-padding respond-print-hide">
                <div className="ben-right">
                    <ul className="ben-left">                    
                        <li className="ben-flyout-wrap">
                            <button className="ben-item ben-secure-button fh-green" type="button" aria-expanded="false">
                                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" id="icon-svg-lock" className="icon-svg-lock" width="11" height="14" viewBox="0 0 11.02 14" ng-svg="icon-svg-lock">
                                    <path d="M7.66,3.88a2.15,2.15,0,0,0-4.3,0v2h4.3Z" style={{fill: 'none'}}></path>
                                    <path  d="M9.39,5.85v-2a3.88,3.88,0,0,0-7.76,0v2A1.72,1.72,0,0,0,0,7.56v4.73A1.72,1.72,0,0,0,1.71,14h7.6A1.72,1.72,0,0,0,11,12.29V7.56A1.71,1.71,0,0,0,9.39,5.85Zm-6-2a2.15,2.15,0,0,1,4.3,0v2H3.36Z"></path>
                                </svg>
                                {t("securePayment")}
                            </button>
                        </li>
                    </ul> 
                </div>
            </header>

            <section className="page-header tour-two tour-list destinations-details text-center">
                <div className="container">
                    <div className="row">
                        <div className="tour-sidebar">
                            <div className="tour-sidebar__featured">
                                <div className="svg-box">
                                    <img alt="loading" src="https://res.cloudinary.com/marketingpyme/image/upload/v1653554305/pulse.svg"/>
                                </div>
                                <h1>{t("processing")}</h1>
                                <div>
                                    {t("processingmsg")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterView/>
        </div>
    );
}


