import React from "react";
import Moment from "moment";
import {BasePacket, Packet} from '../../models/Packet'
import {IHora} from '../../models/IHora'
import {TypeStretch} from '../../models/TypeStretch'
import {getIndexForMapeo, horasIdaAvailable} from '../Util'
import {HoraIdaView} from '../../components/HoraIdaView'
import {IdaVueltaView} from '../../components/IdaVueltaView'
import {TypeTicket} from '../../models/TypetTicket'
import {Vis} from '../../models/Vis'

interface Props {
    packet: BasePacket
    isStretchCollaborator: boolean
    hoursSelected: { [id: number]: IHora }
    datesSelected: { [id: number]: Date }
    disabledDate: boolean
    t: any

    handleStretchSelected(event, stretch: TypeStretch, index: number | string, isStretchCollaborator: boolean): void

    handleChangeHour(hour: IHora, isStretchCollaborator: boolean, index): void
}

export const PacketStretchesView = (props: Props) => {

    const {
        packet,
        isStretchCollaborator,
        hoursSelected,
        datesSelected,
        disabledDate,
        handleStretchSelected,
        handleChangeHour,
        t
    } = props

    if (!packet || (!isStretchCollaborator && !packet.stretches) || (isStretchCollaborator && !packet.collaboratorStretches)) {
        return null
    }

    const stretches = !isStretchCollaborator ? packet.stretches : packet.collaboratorStretches
    const useHoursSelected = !isStretchCollaborator ? packet.hoursSelected : packet.hoursSelectedCollaborator
//console.log(stretches, useHoursSelected)
    /*if (dayDisabled.hours.length === 0) { // todas las horas deshabilitadas
        setHorasDisponibles([])
        pertenece = true
    } else {
        const horasIda = trayecto.horasIda || []
        const horasIdaReduced = horasIdaAvailable(horasIda, dayDisabled)
        setHorasDisponibles(horasIdaReduced)
        if (horasIdaReduced.length === 0) {
            pertenece = true
        }
    }*/

    return (
        <>
            {
                stretches.map(stretch => {
                    let horasDisponibles = []
                    const index = getIndexForMapeo(stretch)
                    const date = datesSelected[index]
                    let hasHours = true
                    if(index) {
                        hasHours = stretch.tramoHora && useHoursSelected[index]
                    }
                    if(!stretch.tramoHora) hasHours = true
                    if (stretch.disabledDays && date) {
                        // miro si el día clicado está en deshabilitados
                        const position = stretch.disabledDays.findIndex(disabledDay =>
                            disabledDay.day === date.getDate() && disabledDay.month === date.getMonth() && disabledDay.year === date.getFullYear()
                        )
     
                        if (position === -1) { // no está en disabledDays, el día está habilitado
                            if (stretch.tramoHora && stretch.horasIda) {
                                //setHorasDisponibles(stretch.horasIda)
                                //useHoursSelected[index] = stretch.horasIda;
                                horasDisponibles = useHoursSelected[index]
                            }
                        } else { // el día está en disabledDays. puede ocurrir varias condiciones
                            // busco el día deshabilitado
                            const dayDisabled = stretch.disabledDays[position]
        
                            // tramoHora: si hours.length === 0 all dia deshabilitado, si hours.length >0 tiene horas deshabilitadas
                            if (!stretch.tramoHora) {// no es tramoHora, el día está deshabilitado
                            } else {
                                if (dayDisabled.hours.length === 0) { // todas las horas deshabilitadas
                                    //setHorasDisponibles([])
                                    // useHoursSelected[index] = [];
                                    horasDisponibles = []
                                } else {
                                    const horasIda = useHoursSelected[index] || []
                                    const horasIdaReduced = horasIdaAvailable(horasIda, dayDisabled)
                                    //setHorasDisponibles(horasIdaReduced)
                                    horasDisponibles = horasIdaReduced
                                    // useHoursSelected[index] = horasIdaReduced;
                                    if (horasIdaReduced.length === 0) { 
                                    }
                                }
                            }
                        }
                    }
                    /*if (stretch.dayDisabled.hours.length === 0) { // todas las horas deshabilitadas
                        setHorasDisponibles([])
                        pertenece = true
                    } else {
                        const horasIda = trayecto.horasIda || []
                        const horasIdaReduced = horasIdaAvailable(horasIda, dayDisabled)
                        setHorasDisponibles(horasIdaReduced)
                        if (horasIdaReduced.length === 0) {
                            pertenece = true
                        }
                    }*/

                    return (
                        <div style={{ margin: '10px',padding: '15px',border: "black",borderStyle: "solid",borderWidth: 1, borderRadius: 3,backgroundColor: isStretchCollaborator? '#ecf3f5' : "inherit"}} key={index}>
                            <div className="destinations-details__content">
                                <h1>{stretch.name}</h1>
                                {
                                    hasHours &&
                                    <p style={{color: datesSelected[index]? 'inherit' : '#0d70ce', fontWeight: 700}} onClick={event => handleStretchSelected(event, stretch, index, isStretchCollaborator)}>
                                    {datesSelected[index] && Moment(datesSelected[index]).format("YYYY-MM-DD") || `1. ${t("selectDate")}`}
                                    <span style={{marginLeft: 10}}>
                                        <i className="fa fa-calendar" aria-hidden="true"/>
                                    </span>
                                </p>
                                }

                                { stretch.tramoHora && hasHours &&
                                
                                <div>
                                    <div>
                                        <div>
                                            <p style={{color: !hoursSelected[index]? '#0d70ce' : 'inherit', fontWeight: 700}}>
                                                {`${!hoursSelected[index]? `2. ${t("selectHour")}`: `${t("selectedHour")}`}`}
                                            </p>
                                        </div>

                                        {
                                            //useHoursSelected && useHoursSelected[index] && useHoursSelected[index].map(horaIda => (
                                                horasDisponibles.length > 0 && horasDisponibles.map(horaIda => (
                                                <HoraIdaView
                                                    key={horaIda.id}
                                                    horaIda={horaIda}
                                                    primary={hoursSelected && hoursSelected[index] && hoursSelected[index].id === horaIda.id}
                                                    handleHour={() => handleChangeHour(horaIda, isStretchCollaborator, index)}
                                                    disabled={disabledDate}
                                                />
                                            ))
                                        }
                                    </div>
                                </div>

                                }

                                {
                                    stretch.vuelta &&
                                    <IdaVueltaView idaVueltaSelected={packet.idaVueltaSelected[index]} t={t}/>
                                }

                                {/*
                                    <div>{t("configuration")}</div> 
                                */
                                }
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}

interface packetTicket {
    numero: string
    type: TypeTicket
    netAmount: number
    taxAmount: number
    total: number
    packet: Packet
    hoursSelected: { [id: number]: IHora }
    datesSelected: { [id: number]: Date }
    aditional: object
    uuid: string
    visitas: Vis
    aditionalInfo: object
}