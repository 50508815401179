import "./disponibility.css";
import CalendarAvailable from '../../../components/CalendarAvailable'
import React, {useRef} from 'react'
import {ItemRow} from './ItemRow'

export const Disponibility = (
  {
    stretch, language, selectedHour, dateClicked, disableAddButton, onAddCart,
    priceLines, extraLines, origin, destination, total, totalDiscount, codeDiscount,
    disabledDays, idaVuelta, idaVueltaSelected,
    clickDateHandler,
    onAdd, onRemove, onAddExtra, onRemoveExtra, onRoundTripHandle, onApplyCoupon, onResetCoupon
  }
) => {
  
  const Route = () => {
    return (
      <div className="routeContainer" style={{backgroundColor: 'lightgray'}}>
        <div className="destiny">
          <div>
            <b>Origen:</b>{origin}
          </div>
          <div>
            <b>Destino:</b>{destination}
          </div>
        </div>
        {idaVuelta && <div className="toggleDestiny">
          <div className="toggleDestiny-option">
            <div>
              {" "}
              <input
                checked={!idaVueltaSelected}
                type="radio"
                name="opcionVuelta"
                value="soloIda"
                onChange={onRoundTripHandle}
              />{" "}
            </div>
            <div>Solo ida</div>
          </div>
          <div className="toggleDestiny-option">
            <div>
              <input
                checked={!!idaVueltaSelected}
                type="radio"
                name="opcionVuelta"
                value="idaVuelta"
                onChange={onRoundTripHandle
                }/>
            </div>
            <div onChange={onRoundTripHandle}>Ida y vuelta</div>
          </div>
        </div>}
      </div>
    );
  };

  const DisponibilityFooter = () => {
    const inputRef = useRef()
    return (
      <div className="disponibilityFooter">
        <div className="disponibilityFooterTotal">
          Total: <b>{total}</b>
        </div>
        <div className="disponibilityFooterActions">
          <div className="disponibilityFooterActionsDiscount">
            <div className="">
              <input type="text" ref={inputRef}/>
            </div>
            <div className="">
              <button onClick={() => {
                // @ts-ignore
                const couponCode = inputRef.current.value
                onApplyCoupon(couponCode)
              }}>Aplicar</button>
            </div>
          </div>
          <div className="">
            <div className="">
              <button
                onClick={() => {if (!disableAddButton) onAddCart()}}
                disabled={disableAddButton}>
                Añadir al carrito
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="row">
      <div className="col-md-6">
        <CalendarAvailable
          clickDateHandler={clickDateHandler}
          language={language}
          dateSelected={dateClicked}
          stretch={stretch}
          selectedHourMinutes={selectedHour}
        />
      </div>
      <div className="col-md-6">
        <div className="disponibility-container">
          <h4>Precios</h4>
          {priceLines.map((p, index) => {
            console.log('price: ', p)
            if (p.disabled) return null
            return(
              <ItemRow
                key={p.id}
                data={p}
                index={index}
                onAdd={onAdd}
                onRemove={onRemove}
              />
            )
          })}
        </div>
        <div className="disponibility-container">
          <h4>Extras</h4>
          {extraLines.map((p, index) => (
            <ItemRow
              key={p.id}
              data={p}
              index={index}
              onAdd={onAddExtra}
              onRemove={onRemoveExtra}
            />
          ))}
        </div>
        <div className="disponibility-container">
          <Route />
        </div>
        <div className="disponibility-container">
          <DisponibilityFooter />
        </div>
      </div>
    </div>
  );
};
