import React, {useRef, useState} from 'react'
import Spinner from 'reactstrap/es/Spinner'
import './CodeDiscountView.css'
import {useInitIdioma} from '../customHooks/useInitIdioma'

interface Props {
  message?: string
  code: number
  loading: boolean
  onClose?: (value: boolean) => void
  onApply: (value: string) => void
  onReset: () => void
}

export const CodeDiscountView = (props: Props) => {
  const {message, code, loading, onClose, onApply, onReset} = props
  
  const inputRef = useRef<HTMLInputElement>()
  const {T} = useInitIdioma()
  
  const [close, setClose] = useState<boolean>(false)

  const handleClose = (value) => {
    if (onClose) {
      onClose(value)
    }
    setClose(value)
  }


  const handleApply = () => {
    const couponCode = inputRef.current.value
    onApply(couponCode)
  }
  
  const handleResetCoupon = () => {
    onReset()
  }
  
  if (close) return null
  if (message && code === 200) {
    return (
      <div
        style={{
          display: 'block'
        }}
      >
        <div
          className='container-discountcode '
          style={{display: 'flex'/*, width: '100%'*/}}
        >
          <h4 style={{flex: 3, textAlign: 'start'}}>{message}</h4>
          
          <button
            style={{flex: 1}}
            className='ben-item ben-translate-button capitalize btn-active btn-code  btn btn-secondary'
            onClick={handleResetCoupon}
          >
            {T('resetCoupon')}
          </button>
        </div>
      </div>
    )
  }

  return (
    <div
      style={{
        display: 'block'
         }}
    >
      <div
        className='container-discountcode '
         style={{display: 'flex'/*, width: '100%'*/}}
      >
        <input 
          style={{flex: 3}}
          type="text" 
          defaultValue=""
          ref={inputRef}
          placeholder={T('couponCodeHere')}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleApply()
            }}}
        />
        {!loading && <button
          className='ben-item ben-translate-button capitalize btn-active btn-code  btn btn-secondary'
          onClick={handleApply}
        >
          {T('apply')}
        </button>}
        {loading && <button
          className='button'
          value="Apply"
          onClick={handleApply}
        ><Spinner/>
        </button>}
      </div>
      {message && 
        <div 
          style={{padding: '10px', backgroundColor: '#f4f4f4', textAlign: 'center', marginTop: 0}}
          className='container-discountcode'
        >
          <h4 style={{flex: 3, textAlign: 'start'}}>{message}</h4>
        </div>
      }      
    </div>
  )
}
