import { useInitIdioma } from 'customHooks/useInitIdioma';
import React from 'react';
import { BodyError } from './bodyError';
import { FooterView } from './FooterView';

export const DefaultPage: React.FC = () => {

    const {t} = useInitIdioma(null)

    return(
        <div className="page-wrapper animated fadeIn">
            <header className="book-embed-nav with-padding respond-print-hide">
                <div className="ben-right">
                    <ul className="ben-left">
                        <li className="ben-flyout-wrap">
                            <button className="ben-item ben-secure-button fh-green" type="button" aria-expanded="false">
                                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" id="icon-svg-lock" className="icon-svg-lock" width="11" height="14" viewBox="0 0 11.02 14" ng-svg="icon-svg-lock">
                                    <path d="M7.66,3.88a2.15,2.15,0,0,0-4.3,0v2h4.3Z" style={{fill: 'none'}}></path>
                                    <path d="M9.39,5.85v-2a3.88,3.88,0,0,0-7.76,0v2A1.72,1.72,0,0,0,0,7.56v4.73A1.72,1.72,0,0,0,1.71,14h7.6A1.72,1.72,0,0,0,11,12.29V7.56A1.71,1.71,0,0,0,9.39,5.85Zm-6-2a2.15,2.15,0,0,1,4.3,0v2H3.36Z"></path>
                                </svg>
                                {t("securePayment")}
                            </button>
                        </li>
                    </ul> 
                </div>

            </header>
            <br></br>
            <br></br>
            <br></br>
            <BodyError message={`404: ${t("pageNotFound")}`} listParagraph={[t("pageNotFoundmsg")]}/>
            <FooterView/>
        </div>
    );
}