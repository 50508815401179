import {Button} from "@material-ui/core";
import {IHora} from '../models/IHora'

interface Props {
    horaIda: IHora
    primary: boolean
    handleHour(horaIda: IHora): void
    disabled: boolean
}

export const HoraIdaView = ({horaIda, primary, handleHour, disabled}: Props) => {
    return (
        <Button style={{height: 35, outline: "none"}} onClick={() => {handleHour(horaIda)}} key={horaIda.id} disabled={disabled}>
            <p style={{ verticalAlign: "middle", backgroundColor: primary ? 'green' : undefined, color: primary ? 'white' : 'black', borderWidth: 1, borderColor: 'black', borderRadius: 5, paddingLeft: 5, paddingRight: 5, marginRight: 5}}>
                {`${horaIda.hours > 9 ? horaIda.hours : '0' + horaIda.hours}:${horaIda.minutes > 9 ? horaIda.minutes : '0' + horaIda.minutes}`}
            </p>
        </Button>
    )
}
