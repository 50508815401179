import {BasePacket} from './Packet'

export default class CollaboratorPacket extends BasePacket {
    operator_id: number
    operatorName: string
    operationLine: any
    
    constructor(props: CollaboratorPacket) {
        super(props)
        Object.assign(this, props)
    }
}
