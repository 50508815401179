import * as Sentry from "@sentry/react";
const baseURL = process.env.REACT_APP_baseURL
const prefix = process.env.REACT_APP_PREFIX;

export const getAllData = async (publicToken, at?) => {
    const baseURLTicando = process.env.REACT_APP_baseURLTicando
    try{
        // Control de errores hecho
        const request = await fetch(
            !at? `${baseURLTicando}/data` : `${baseURLTicando}/data_affiliate?at=${at}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'x-provider': 'creaticket',
                    'Authorization': `Bearer ${publicToken}`
                }
            }
        )
        return request.json()
    }catch(e){
        console.log('getAllData - e: ',e);
        Sentry.captureException(new Error("Request - getAllData " + e));
        throw e;
    }
}

//TODO cambiar a Main
export const getChannels = async (publicToken) => {
    // Control de errores hecho
    try{
        const request = await fetch(`${baseURL}${prefix}/tmt/getChannels?publicToken=${publicToken}`, {
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            method: 'GET'
        });
        return request.json();
    }catch(e){
       // alert('tiempo excedido. Inténtelo más tarde.')
       // Sentry.captureException(new Error("Request - getChannels"));
       throw e;
    }

}