import React from 'react';
import {SecureBagde} from '../../components/SecureBagde'

export default function CartTotal({ totalAmount, money, t }) {
    return <div>
        <div className="book-form-totals book-form-totals2 has-secure-badge has-totals">
            <div className="table valign-middle full-width">
                <div className="tr">
                    <div className="td">
                        <SecureBagde t={t}/>
                    </div>
                    <div className="td"><div className="text-right">Total: {totalAmount.toFixed(2)} {money}{/*money === 'EUR' ? '€' : '$'*/}</div> </div>
                </div>
            </div>
        </div>
    </div>;
}
