import {Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@material-ui/core'

import React, {useState, useEffect, useRef} from 'react'
import '../../styles/style.css'
import {useHistory} from 'react-router'
import {RouteComponentProps} from 'react-router'
import {
    addVisitanteGlobal,
    generateTokenticket, getIndexForMapeo, lengths,
    quitarVisitanteGlobal, visOK
} from '../Util'
// import '../../styles/_variables.scss'
// import '../../styles/_calendar.scss'
import {PacketStretchesView} from "./PacketStretchesView"
import {HeaderView} from "../../components/HeaderView"
import {FooterView} from "../../components/FooterView";
import {CtaView} from "../../components/CtaView";
import {DialogDateSelectView, ISelected} from "../../components/DialogDateSelectView";
import {PricesView} from "../../components/PricesView";
import {round} from "../Util";
import {BasePacket} from '../../models/Packet'
import OperationLine from '../../models/OperationLine'
import BusinessUnit from '../../models/BusinessUnit'
import {IHora} from '../../models/IHora'
import {getOperationLine} from '../OperationLine/Request'
import {addPacketToCart, getCollaboratorPacketByID, getPacketByID} from './Request'
import {Vis} from '../../models/Vis'
import {IDisabledDay} from '../../models/IDisabledDay'
import {TypeTicket} from '../../models/TypetTicket'
import { getChannels } from 'Request/Request'
import {Carousel} from "react-responsive-carousel"
import { InfoWeb } from 'components/InfoWeb'
import { modalError } from 'Modules/Payment/Request'
import Spinner from 'reactstrap/es/Spinner'
import * as Sentry from "@sentry/react";
import Swal from 'sweetalert2'
import { useInitIdioma } from 'customHooks/useInitIdioma'
import { BodyError } from 'components/bodyError'

interface PacketParams {
    ticando_id?: string
    packet_id?: string // id de paquete
    operationLine_id?: string
    businessUnit_id?: string
    collaborator_id?: string
    operator_id?: string
    public?: string
}

interface PacketProps extends RouteComponentProps<PacketParams> {
    isCollaboratorPacket?: boolean
}

export const PacketPage: React.FC<PacketProps> = (props: PacketProps) => {

    const publicToken = props.match.params.public;
    const history = useHistory();
    const {idioma, t, i18n} = useInitIdioma(publicToken) // Custom Hook

    const {isCollaboratorPacket} = props
    const packet_id = Number(props.match.params.packet_id)
    const operationLine_id = Number(props.match.params.operationLine_id)
    const operator_id = Number(props.match.params.operator_id)
    const init: Vis = {
        names: [],
        indices: [],
        vis: [],
        price: [],
        base: 0.00,
        total: 0.00,
        totalDiscount: 0.00,
        extras: [],
        disabledButtons: false,
        discount: null
    }

    const [packet, setPacket] = useState<BasePacket>()
    const [operationLine, setOperationLine] = useState<OperationLine>()
    const [businessUnit, setBusinessUnit] = useState<BusinessUnit>()
    const [channels, setChannels] = useState<any>();
    const [disabledCanal, setDisabledCanal] = useState<boolean>(false)

    const [openDatePopup, setOpenDatePopup] = useState<boolean>(false)
    const [openReservarPopup, setOpenReservarPopup] = useState<boolean>(false)
    const [visitas, setVisitas] = useState<Vis>(init)
    const [totalSeats, setTotalSeats] = useState<number>(0)
    const [disabled, setDisabled] = useState<boolean>(false)
    const [disabledBackend, setDisabledBackend] = useState<boolean>(false)
    const [disabledStretches, setDisabledStretches] = useState<any>([])
    const [adding, setAdding] = useState<boolean>(undefined);
    const [monedasGreenpay, setMonedasGreenpay] = useState<any>();
    const [allowPacket, setAllowPacket] = useState<boolean>(true) // Si alguno de los servicios pertenecientes al paquete tiene el campo allowInPackage a false no se debe mostrar el paquete
    const [minimumPassengerReach, setMinimumPassengerReach] = useState<boolean>(true)
    const [currencyCredomatic, setCurrencyCredomatic] = useState<string>()

    // para gestionar el servico seleccionado para el que se abre el calendario
    const [selected, setSelected] = useState<ISelected>({
        stretchSelected: null,
        indexSelected: null,
        isStretchCollaborator: null
    })

    const [hoursSelected, setHoursSelected] = useState<{ [id: number]: IHora }>({})
    const [datesSelected, setDatesSelected] = useState<{ [id: number]: Date }>({})

    let description = useRef('')
    let canalSi = false;

    useEffect(() => {
        const querystring = window.location.search
        const params = new URLSearchParams(querystring)
        try {
            if(params.has('uuid_dmn')) {
                let uuid_dmn = params.get('uuid_dmn') 
                localStorage.setItem('uuid_dmn', uuid_dmn)
            } else {
                localStorage.setItem('uuid_dmn', 'dmn')      
            }
        } catch(e) {
            if(publicToken) {
                history.push(`/${publicToken}/cookies`)
            }
        }
    },[])
    
    useEffect(() => {
        if(!channels) {
            getChannels(publicToken).then((res) => {
                if(res.msg === 'No TMT') {
                    setChannels({msg: 'notmt. Code: 48'})
                    if(res.currency) setCurrencyCredomatic(res.currency)
                } else if(res.msg === 'GREENPAY') {
                    setMonedasGreenpay(res.monedasDisponibles)
                    setChannels({msg: 'greenpay. Code: 49'})
                } else if(res.msg === 'No credenciales') {
                    setChannels({msg: 'Servicio en mantenimiento. Code: 50'})
                } else {
                    setChannels(res);
                }               
            }).catch(e => {
                Sentry.captureException(new Error("StretchPage - getChannels"));
                setChannels({msg: 'error . Code: 51'})
            }) 
        }
    }, [channels])


    useEffect(() => {
        if (!packet && operationLine) {
            getPacketByID(operationLine, packet_id).then(res => {

                let aux = true
                res.collaboratorStretches.forEach(cs => {
                  // if(!cs.allowInPackage) aux = false // . TODO
                   if(cs.money_id !== res.money_id) aux = false
                })

                setAllowPacket(aux)
                setPacket(res)
            })
        }
    }, [operationLine])

    useEffect(() => {
        i18n.changeLanguage(idioma);  
        if (isCollaboratorPacket) {
            // paquete en colaboracion
            if (!packet) {
                getCollaboratorPacketByID(packet_id, operator_id, publicToken).then(res => {
                    if(res.statusCode === 401){
                        Sentry.captureException(new Error("CollaboratorPacket - Client no encontrado"));
                        history.push(`/${publicToken}/error/${'Cliente no encontrado'}`)
                        return null;
                    }
                    setPacket(res)
                }).catch(e => {
                    Sentry.captureException(new Error("CollaboratorPacket - getCollaboratorPacketByID"));
                    history.push(`${publicToken}/error/Ha ocurrido un error - getCollaboratorPacketByID`)
                })
            }
        } else {
            //paquete
            if (!operationLine) {
                getOperationLine(operationLine_id, publicToken).then(res => {
                    if(res.statusCode === 401){
                        Sentry.captureException(new Error("Packet - Client no encontrado"));
                        history.push(`/${publicToken}/error/${'Cliente no encontrado'}`)
                        return null 
                    }
                    setOperationLine(res)
                    setBusinessUnit(res.businessUnit)
                }).catch(e => {
                    Sentry.captureException(new Error("Packet - getCollaboratorPacketByID"));
                    history.push(`${publicToken}/error/Ha ocurrido un error - getOperationLine`)
                })
            }

        }

    }, [operationLine])

    useEffect(() => {
        if (packet && packet.prices) {
            setDisabled(packet.prices.length === 0)
            if (visitas.indices.length===0) { //
                let auxVis: number[] = []
                let auxPrice: number[] = []
                let auxNames: string[] = []
                let indices: number[] = []
                packet.prices.forEach((precio) => {
                    auxVis.push(0)
                    auxPrice.push(0.00)
                    auxNames.push(precio.passengerCategory.name)
                    indices.push(precio.passengerCategory.id)
                    if(precio.passengerCategory.minToReserve && precio.price > 0) setMinimumPassengerReach(false)
                })
                setVisitas({
                    names: auxNames,
                    indices,
                    vis: auxVis,
                    price: auxPrice,
                    base: 0.00,
                    total: 0.00,
                    totalDiscount: 0.00,
                    extras: [],
                    disabledButtons: false,
                    discount: visitas.discount
                })
            }
            setTotalSeats(0)
        } else {
            setDisabled(true)
        }
    }, [packet])

    useEffect(() => {
        if(packet) {
            console.log(currencyCredomatic)
            if(channels && !channels.hasOwnProperty('msg')){ // Si no tiene msg es porque tiene canales de tmt
                channels.map(c => {
                    if(c.currencies === packet.primaryMoney.alphabeticCode){
                        canalSi = true;
                    }
                })
                setDisabledCanal(!canalSi)
            } else if(channels && channels.msg && channels.msg.includes('greenpay') && packet) {
                let moneda = packet.primaryMoney.alphabeticCode
                if(monedasGreenpay.includes(moneda)){
                    canalSi = true;
                }
                
            } else if(currencyCredomatic && packet) {
                if(currencyCredomatic !== packet.primaryMoney.alphabeticCode) {
                    canalSi = false
                }
            }
            setDisabledCanal(!canalSi)
        }
    }, [channels])

    useEffect(() => {
        if(packet) { // Controlo que el paquete no tenga ningún servicio deshabilitado
            let stretches = [...packet.stretches, ...packet.collaboratorStretches]
            let aux = []
            stretches.forEach(s => {
                if(s.disableWeb || s.disabled_at) {
                    aux.push(s.name)
                }
            })
            setDisabledStretches(aux)
        }
    }, [packet])

    const handleStretchSelected = (event, stretch, index, isStretchCollaborator) => {
        setSelected({
            stretchSelected: stretch,
            indexSelected: index,
            isStretchCollaborator
        })
    }

    const handleChangeHour = (hour: IHora, isStretchCollaborator: boolean, index) => {
        if (!isStretchCollaborator) {
            hoursSelected[index] = hour
            setHoursSelected(prevState => ({
                ...prevState,
                [index]: hour
            }))
        } else {
            hoursSelected[index] = hour
            setHoursSelected(prevState => ({
                ...prevState,
                [index]: hour
            }))
        }
    }

    const closeClickDateHandler = () => {
        setOpenDatePopup(false)
    }

    const closeClickReservarHandler = () => {
        setOpenReservarPopup(false)
    }

    // Handlers para el DialogDateSelectView que abre una ventana de dialogo con el calendario
    const handleSetOpenDatePopup = (value) => {
        setOpenDatePopup(value)
    }

    const handleDialogActions = () => {
        setSelected(prevState => ({
            ...prevState,
            stretchSelected: null
        }))
    }

    const handleDatesSelelected = (dateSelected, index, isStretchCollaborator) => {

        const old_hour = hoursSelected[index]
        const stretch = selected.stretchSelected
        const month = dateSelected.getMonth()
        const day = dateSelected.getDate()
        const disabledDay = stretch.disabledDays.find(date => date.month === month && date.day === day)
        if(disabledDay && disabledDay.hours && old_hour) {
            const disabledHour = disabledDay.hours.find(hour => hour.id === old_hour.id)
            if(disabledHour) {
                hoursSelected[index] = index.includes('_') ? packet.hoursSelectedCollaborator[index][0] : packet.hoursSelected[index][0]
            }

        }
        if (!isStretchCollaborator) {
            setDatesSelected(prevState => ({
                ...prevState,
                [index]: dateSelected
            }))
        } else {
            setDatesSelected(prevState => ({
                ...prevState,
                [index]: dateSelected
            }))
        }
        setSelected(prevState => ({
            ...prevState,
            stretchSelected: null
        }))
    }

    const addVisitantePrices = (event: any) => {
        const index = event.currentTarget.value
        if (packet && index) {
            const {auxVis,limite} = addVisitanteGlobal(visitas, packet, index, packet.prices)
            packet.prices.forEach(price => {
                if(price.passengerCategory.minToReserve && price.price > 0) {
                    const name = price.passengerCategory.name
                    const index = auxVis.names.indexOf(name)
                    const nReserve = auxVis.vis[index]
                    if(nReserve >= price.passengerCategory.minToReserve){
                        setMinimumPassengerReach(true)
                    } else {
                        setMinimumPassengerReach(false)
                    }
                }
            })
            if (auxVis !== null) setVisitas(auxVis)
            let auxTot = totalSeats
            auxTot++
            setTotalSeats(auxTot)
        } else {
        }
    }

    const quitarVisitantePrices = (event: any) => {
        const index = event.currentTarget.value
        if (packet && index) {
            const auxVis = quitarVisitanteGlobal(visitas, packet, index, packet.prices)
            packet.prices.forEach(price => {
                if(price.passengerCategory.minToReserve && price.price > 0) {
                    const name = price.passengerCategory.name
                    const index = auxVis.names.indexOf(name)
                    const nReserve = auxVis.vis[index]
                    if(nReserve >= price.passengerCategory.minToReserve){
                        setMinimumPassengerReach(true)
                    } else {
                        setMinimumPassengerReach(false)
                    }
                }
            })
            if (auxVis !== null) setVisitas(auxVis)
            let auxTot = totalSeats - 1
            setTotalSeats(auxTot)
        }
    }

    // compruebo las fechas deshabilitadas
    const someDateDisabled = (packet: BasePacket, datesSelected: {[id: number]: Date}, hoursSelected) => {
        return packet.stretches.reduce((previous, currentStretch) => {
            const index = getIndexForMapeo(currentStretch)
            const result = checkDisabledDay(currentStretch.disabledDays, datesSelected[index], hoursSelected[index])
            return previous || result
        }, false)
    }

    // compruebo si una fecha (sólo dia, mes y año) está en una lista de fechas deshabilitadas
    const checkDisabledDay = (disabledDays: IDisabledDay[], day: Date, hour) => {
        if (!disabledDays || !day) {
            return false
        }
        let indice = disabledDays.findIndex(disableDay =>
            disableDay.year === day.getFullYear() &&
            disableDay.month === day.getMonth() &&
            disableDay.day === day.getDate() 
        )

        if(indice !== -1){
                // Compruebo si el dia seleccionado que está deshabilitado tiene la hora deshabilitada, en ese caso se considera deshabilitado
                let index;
                if(hour !== undefined){
                    index = disabledDays[indice].hours.findIndex(h => h.hours === hour.hours && h.minutes === hour.minutes)
                }else{
                    return true; // indice distinto de -1, es decir, se ha encontrado el dia seleccionado en el array de diasDisabled
                }
                
                return index !== -1
            }else{
                return false;
            }

    }

    // todos servicios con fecha seleccionada
    const datesSelectedOk = (packet: BasePacket, datesSelected: {[id: number]: Date}) => {
        const numberStretches = packet.stretches.length
        const numberCollaboratorStretches = packet.collaboratorStretches.length
        const entriesNumber = Object.entries(datesSelected).length
        return entriesNumber === numberStretches + numberCollaboratorStretches
    }

    // compruebo si a todos los servicios con hora de salida se ha asignado hora
    const hoursSelectedOk = (packet: BasePacket, hoursSelected: {[id: number]: IHora}) => {
        const stretchesWithHorasIda = packet.stretches.reduce((previousValue, currentValue) => {
            if (currentValue.tramoHora && currentValue.horasIda && currentValue.horasIda.length>0) {
                return ++previousValue
            }
            return previousValue
        }, 0)
        const collaboratorStretchesWithHorasIda = packet.collaboratorStretches.reduce((previousValue, currentValue) => {
            if (currentValue.tramoHora && currentValue.horasIda && currentValue.horasIda.length>0) {
                return ++previousValue
            }
            return previousValue
        }, 0)

        const entriesNumber = Object.entries(hoursSelected).length

        return entriesNumber === stretchesWithHorasIda + collaboratorStretchesWithHorasIda
    }

    const addCart = async () => {
        setAdding(true)
        if (packet /*&& operationLine && businessUnit*/) {
            let tokenTicket;
            try {
                tokenTicket = localStorage.getItem('tokenTicket')
                if (!tokenTicket) {
                    tokenTicket = generateTokenticket(20)
                    localStorage.setItem('tokenTicket', tokenTicket)
                }
            } catch(e) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Es necesario activar las cookies de terceros para poder realizar la reserva.',
                    footer: `Ayuda: En Chrome dirígete a chrome://settings/cookies para permitirlo.`
                  }) 
            }

            const additional = {
                packetName: packet.name,
                packetDescription: packet.description,
                tokenticket: tokenTicket,
                description: description
            }

            let uuid;
            try {
                uuid = localStorage.getItem('tokenTicket')
            } catch(e) {
                if(publicToken) {
                    history.push(`/${publicToken}/cookies`)
                }
            }

            const netAmount = visitas.base
            // Pongo 0 si estamos en otra pasarela de pago que no es tmt
            let channel = (channels.msg && (channels.msg.includes('notmt') || (channels.msg.includes('greenpay')))) ? {id: 0} : channels.find(c=>packet.primaryMoney.alphabeticCode === c.currencies);
            
            const ticket = {
                numero: generateTokenticket(isCollaboratorPacket? lengths.collaboratorPacket: lengths.packet),
                type: isCollaboratorPacket? TypeTicket.CollaboratorPacket : TypeTicket.Packet,
                netAmount: netAmount,
                taxAmount: netAmount * packet.tax.value,
                total: round(visitas.total),
                packet,
                hoursSelected,
                datesSelected,
                idaVueltaSelected: {},
                additional,
                uuid: uuid || undefined,
                visitas,
                aditionalInfo: packet.aditionalInfo,
                money : packet.primaryMoney ? packet.primaryMoney.alphabeticCode : 'EURcodigo',
                channel_id: channel.id,
                urlImage: packet.dataweb ? packet.dataweb.image1 : 'https://res.cloudinary.com/marketingpyme/image/upload/w_540,h_300,c_fill/v1639043362/civitrip/plugin/sinImagen.jpg',          
            }

            addPacketToCart(ticket, publicToken).then(res => {
                setAdding(false)
                let message: string = "";
                let info 
                switch (res.code) {
                    case 200: 
                        if (!uuid) {
                            try {
                                localStorage.setItem('tokenTicket', res.payload.token)
                            } catch(e) {
                                if(publicToken) {
                                    history.push(`/${publicToken}/cookies`)
                                }
                            }
                        }
                        history.push({
                            pathname: `/${publicToken}/cart/`
                        })
                        break;
                    case 400:
                        message = t("tokenNoExists"); // El token no existe.
                        break
                    case 404:
                        message = t("stretchInCart") //'Ya tiene agregada esa experiencia al carrito, si quiere modificarla tendrá que eliminarla primero del carrito.'
                        break
                    case 410:
                        message = t("errorCreateTicket") //"Error al generar el ticket."
                        break
                    case 412:
                        message = t("obtainError") //"Hemos obtenido un error."
                        break
                    case 413:
                        info = true;
                        message = `En este momento no tenemos disponibilidad para el día seleccionado.  ${t("thenDisponibility")}`  //t("notEnoughCapacity") //"No hay aforo suficiente."
                        break
                    case 414:
                        info = true;
                        message =  t("serviceNotAvailable") //"El servicio que quiere reservar no está disponible."
                        break
                    case 415:
                        info = true;
                        message = t("dateDisabled") //"El servicio está deshabilitado para esa fecha."
                        break
                    case 416:
                        info = true;
                        message = t("hourDisabled") //"El servicio está deshabilitado para esa hora."
                        break
                    case 500:
                        message = t("serverError") //"Error interno del servidor."
                        break
                    default:
                        message = t("Sorry, we got an unexpected error.") //"Lo siento, hemos obtenido un error inesperado."
                        break                
                }
                if(message !== ""){
                    Sentry.captureException(new Error(`CollaboratorStretch - ${message}`));
                    modalError(message)
                }
                /*history.push({
                    pathname: `/${publicToken}/cart/`,
                    search: publicToken
                })*/
            }).catch(e => {
                setAdding(false)
                console.log(e)
                Sentry.captureException(new Error("Packet - Añadir "+e));
                modalError(t("errorAddCart"))
            })
        }
    }

    let disableAddCart = true

    if (packet) {
        disableAddCart = someDateDisabled(packet, datesSelected,hoursSelected) ||
            !hoursSelectedOk(packet, hoursSelected) ||
            !datesSelectedOk(packet, datesSelected) || !visOK(visitas)
    }

    const images = []
    if (packet && packet.dataweb) {

        for(let index in packet.dataweb) {
            if(index.startsWith('image')) {
                const temp = {
                    original: packet.dataweb[index].replace('http://','https://'),
                    thumbnail: packet.dataweb[index].replace('http://','https://')
                }
                images.push(temp)
            }
        }

        /*for (let i=1; i<=15; i++) {
            if (packet.dataweb[`image${i}`]) {
                if(packet.dataweb[`image${i}`] !== " "){
                    const temp = {
                        original: packet.dataweb[`image${i}`].replace('http://','https://'),
                        thumbnail: packet.dataweb[`image${i}`].replace('http://','https://')
                    }
                    images.push(temp)
                }               
            }
        }*/
    }
    if(images.length === 0){
        images.push(
            {
                original: "https://res.cloudinary.com/marketingpyme/image/upload/w_540,h_300,c_fill/v1639043362/civitrip/plugin/sinImagen.jpg",
                thumbnail: "https://res.cloudinary.com/marketingpyme/image/upload/w_540,h_300,c_fill/v1639043362/civitrip/plugin/sinImagen.jpg"
            })
    }

    let disabledStretchesNames = ''
    disabledStretches.forEach(s => {
        if(!disabledStretchesNames.includes(s)) {
            disabledStretchesNames = disabledStretchesNames === '' ? s : disabledStretchesNames + ', ' + s
        }
    })


    return (
        <div className="page-wrapper animated fadeIn">
            <HeaderView publicToken={publicToken}/>                

            <section className="page-header tour-two tour-list destinations-details">
                <div className="container">
                    <div className="row">

                        {
                            (!packet || !channels) &&
                            <div style={{top:'50%',left:'50%,',display: 'flex',justifyContent: 'center', alignItems: 'center'}}>             
                                <Spinner color="primary" style={{width: 100, height: 100}}></Spinner>
                            </div> 
                        }

                        {
                            !allowPacket &&
                            <BodyError message={t("packageNotAvailable")} listParagraph={null}/>
                        }

                        {
                            packet && disabledStretches.length > 0 &&      
                            <BodyError message={t("packageNotAvailable")} listParagraph={disabledStretches.length > 0 ? (disabledStretches.length === 1 ? [`${t("packageNotAvailablemsgsingle")} ${disabledStretches[0]}`, t("thanksSorry")] : [`${t("packageNotAvailablemsgvarious")} ${disabledStretchesNames}`, t("thanksSorry")]) : null}/>
                        }

                        {
                            channels && channels.hasOwnProperty('msg') && channels.msg.includes('Code: 5')&& 
                            <BodyError message={channels.msg ? channels.msg : 'error'} listParagraph={[t("requestChannelsErrormsg") ,t("thanksSorry")]}/>    
                        }

                        {
                            packet && disabledStretches.length === 0 && (channels && (!channels.hasOwnProperty('msg') || (channels.hasOwnProperty('msg') && !channels.msg.includes('Code: 5')))) && allowPacket &&
                            <>
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-8">
                                {
                                    disabledCanal && channels && channels.hasOwnProperty('msg') && !channels.msg.includes('greenpay') && !currencyCredomatic &&
                                    <div style={{backgroundColor:'red', color: 'white'}}>
                                        {t("noMoneyTMT")}
                                    </div>
                                }   
                                {
                                    disabledCanal && channels && channels.hasOwnProperty('msg') && channels.msg.includes('greenpay') && !currencyCredomatic &&
                                    <div style={{backgroundColor:'red', color: 'white'}}>
                                        {t("noMoneyGreenpay")}
                                    </div>
                                }  
                                {
                                    disabledCanal && currencyCredomatic && currencyCredomatic !== packet.primaryMoney.alphabeticCode &&
                                    <div style={{backgroundColor:'red', color: 'white'}}>
                                        {t("noMoneyCredomatic")}
                                    </div>
                                }  
                                <div className="destinations-details__content">
                                    <h3 className="destinations-details__title">{packet.name}</h3>
                                    <Carousel autoPlay infiniteLoop showStatus={false}>
                                        {
                                            images.filter(img => img.original !== '').map(image => {
                                                return(
                                                    <div key={image.original}>
                                                        <img src={image.original.replace("w_250,h_200,c_fill","c_fill").replace("w_450,h_350","")} alt=''/>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Carousel>                                               
                                    <InfoWeb trayecto = {packet} orden={1} publicToken={publicToken}/>                              
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-5 col-lg-4">
                                <div className="tour-sidebar">
                                    <div className="tour-sidebar__featured">
                                        <CtaView t={t} />
                                        <>
                                            <DialogDateSelectView
                                                selected={selected}
                                                handleSetOpenDatePopup={handleSetOpenDatePopup}
                                                handleDatesSelelected={handleDatesSelelected}
                                                handleDialogActions={handleDialogActions}
                                                t={t}
                                                idioma={idioma}
                                            />
                                        </>

                                        <div className="fechas">
                                            {/*Servicios que incluye el paquete*/}
                                            <div className="col-lg-12">
                                                {/*servicios propios*/}
                                                <PacketStretchesView
                                                    packet={packet}
                                                    isStretchCollaborator={false}
                                                    hoursSelected={hoursSelected}
                                                    datesSelected={datesSelected}
                                                    disabledDate={false}
                                                    handleStretchSelected={handleStretchSelected}
                                                    handleChangeHour={handleChangeHour}
                                                    t={t}
                                                />

                                                {/*servicios en colaboracion*/}
                                                <PacketStretchesView
                                                    packet={packet}
                                                    isStretchCollaborator
                                                    hoursSelected={hoursSelected}
                                                    datesSelected={datesSelected}
                                                    disabledDate={false}
                                                    handleStretchSelected={handleStretchSelected}
                                                    handleChangeHour={handleChangeHour}
                                                    t={t}
                                                />
                                            </div>
                                        </div>

                                        <Dialog onClose={closeClickDateHandler} aria-labelledby="customized-dialog-title" open={openDatePopup}>
                                            <DialogTitle id="customized-dialog-title">{t("dateNotAvailable")}</DialogTitle>
                                            <DialogContent dividers>
                                                <Typography gutterBottom>
                                                    <b>{t("dateNotAvailablemsg")}</b>
                                                </Typography>
                                            </DialogContent>
                                            <DialogActions>
                                                <button type="button" className="btn btn-primary" onClick={closeClickDateHandler}>{t("close")}</button>
                                            </DialogActions>
                                        </Dialog>
                                        <PricesView
                                            prices={packet.prices}
                                            money={packet.primaryMoney ? packet.primaryMoney.alphabeticCode : 'alphabeticCode'}
                                            tax={packet.tax}
                                            visitas={visitas}
                                            addVisitantePrices={addVisitantePrices}
                                            quitarVisitantePrices={quitarVisitantePrices}
                                            publicToken={publicToken}
                                            minimumPassengerReach={minimumPassengerReach}
                                        />

                                        {
                                            disabled &&
                                            <div>
                                                {t("experienceNotAvailablemsg")}
                                            </div>
                                        }             

                                        <Dialog onClose={closeClickReservarHandler} aria-labelledby="customized-dialog-title" open={openReservarPopup}>
                                            <DialogTitle id="customized-dialog-title">{t("selectItem")}</DialogTitle>
                                            <DialogContent dividers>
                                                <Typography gutterBottom>
                                                    <b>{t("selectItemmsg")}</b>
                                                </Typography>
                                            </DialogContent>
                                            <DialogActions>
                                                <button type="button" className="btn btn-primary" onClick={closeClickReservarHandler}>{t("close")}
                                                </button>
                                            </DialogActions>
                                        </Dialog>

                                        <div className="book-form-totals">
                                            <p className='totalAlign'>
                                                <b>Total: </b> {visitas.total.toFixed(2)} {packet.primaryMoney ? packet.primaryMoney.alphabeticCode : 'packet.primaryMoney.alphabeticCode' }
                                            </p>
                                        </div>

                                        {
                                            !adding &&
                                            <button type="button" disabled={disableAddCart || disabledCanal || !minimumPassengerReach} className="btn-huge btn-wide btn-blue test-add-to-cart-action-enabled" onClick={addCart}>
                                                {t("addCart")}
                                            </button>    
                                        }
                                        {
                                            adding && 
                                            <button type="button" disabled={disableAddCart || disabled || disabledCanal || !minimumPassengerReach} className="btn-huge btn-wide btn-blue test-add-to-cart-action-enabled" >
                                               <Spinner/>
                                            </button>
                                        }
                                        {                                                 
                                            <InfoWeb trayecto = {packet} orden={2} publicToken={publicToken}/>
                                        } 
                                    </div>
                                </div>
                            </div>
                            </>
                        }
                    </div>
                </div>
            </section>
            <FooterView/>
        </div>
    )
}
