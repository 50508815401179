import {IHora} from './IHora'
import Price from './Price'
import Stretch from './Stretch'
import {Tax} from './Tax'
import OperationLine from './OperationLine'


export class BasePacket {
    id: number
    hoursSelected: {[stretch_id: number]: IHora[]} // horas permitidas para la venta
    hoursSelectedCollaborator: {[stretchId_operatorId: number]: IHora[]} // horas permitidas para la venta
    idaVueltaSelected: {[stretch_id: number]: boolean}
    idaVueltaSelectedCollaborator: {[index: number]: boolean}
    image: string
    name: string
    prices: Price[]
    stretches: Stretch[]
    tax: Tax
    tax_id: number
    aditionalInfo: string
    collaboratorStretchValues: {stretch_id: number, operator_id: number}[]
    collaboratorStretches: Stretch[]
    description: string
    article_id: number
    money: string
    quantityArticle: number
    dataweb: any
    primaryMoney: any
    capacity: number
    extras?
    
    
    constructor(props: BasePacket) {
        Object.assign(this, props)
    }
}

export class Packet extends BasePacket {
    operationLine_id: number
    operationLine: OperationLine
    money_id: any
    constructor(props: Packet) {
        super(props)
        Object.assign(this, props)
    }
}
