import React from "react";

interface Props {
    idaVueltaSelected: boolean
    t: any
}

export const IdaVueltaView = ({idaVueltaSelected, t}: Props) => {
    if (idaVueltaSelected) {
        return (
            <WrapperShowVuelta>{t("roundTrip")}</WrapperShowVuelta>
        )
    }
    return <WrapperShowVuelta>{t("singleJourney")}</WrapperShowVuelta>
}
 
const WrapperShowVuelta = props => (
    <span
        style={{
            backgroundColor: '#0d70ce',
            color: 'white',
            paddingLeft: 5,
            paddingRight: 5,
            minHeight: 30,
            minWidth: 50
        }}
        {...props}
    >
        {props.children}
    </span>
)
