export const OrigenDestino = ({sentidoOrigen, trayecto, handleSentidoTrayecto}) => {
    return (
        <>
            <div>
                <strong style={{paddingLeft: 20}}>Origen: {sentidoOrigen ? trayecto.origin : trayecto.destination}</strong>
            </div>
            <button style={{border: '1px solid #1f67ad', background: '#e4f1ff', borderRadius: 4}} onClick={handleSentidoTrayecto}>
                <i className="bi bi-arrow-left-right" style={{color: '#1f67ad'}}></i>
            </button>
            <div>
                <strong>Destino: {sentidoOrigen ? trayecto.destination : trayecto.origin}</strong>
            </div>
        </>
    )
}