import Cart from "models/Cart";
import { PaymentGateway } from "./paymentGateway";
import {getPaymentToken, modalError, getMerchantByIdTicando, publish, EventName} from "./Request";
import * as Sentry from "@sentry/react";
import {getCart} from '../Cart/Request'
import Swal from "sweetalert2"

export  class MultiSafePay extends PaymentGateway{

    set_url_post(url_post): void {
      this.url_post = url_post;
    }
    set_url_ok(url_ok): void {
       this.url_ok = url_ok;

    }
    set_url_ko(url_ko): void {
       this.url_ko = url_ko;
    }

    tokenTicket: string;
    publicToken: string;
    urlBasePost: string;
    namePasarela: string;
    totalAmount: number;
    description: string;
    url_post: string;
    url_ok: string;
    url_ko: string;
    name: string;
    surname: string;
    telephone: string;
    email: string;
    additional_info: any;
    money: string;
    cart: Cart;
    t:any
    environment: any; // dev o prod
    history
    constructor(tokenTicket:string, publicToken: string,  totalAmount: number, description: string, name: string, surname: string, telephone: string, email: string, additional_info: any, money: string, cart: Cart,t: any) {
        super();
        this.tokenTicket = tokenTicket;
        this.publicToken = publicToken;
        this.namePasarela = 'MULTISAFEPAY';
        this.totalAmount = totalAmount;
        this.description = description;
        this.name = name;
        this.surname = surname;
        this.telephone = telephone;
        this.email = email;
        this.additional_info = additional_info;
        this.money = money;
        this.cart = cart;
        this.t = t;
        this.environment = process.env.REACT_APP_ENVIRONMENT
      }

    setHistory = (history) => {
        this.history = history
    }  
      
    setIdioma(t) {
        this.t = t
    }

    pay = async (): Promise<void> => {
           
        // compruebo si el carrito ya ha sido pagado
        const cartResponse = await getCart(this.cart.token, this.publicToken)
        // console.log('cartResponse: ', cartResponse)
        if (cartResponse.code === 433) { // 419 carrito pagado
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: this.t('cartPaid'), 
            })
            localStorage.removeItem('tokenTicket')
            publish(EventName.OnTicketTokenDeleted, null)
            publish(EventName.OnFinish, null)
            return
        } else if (cartResponse.code === 420) { // 420 carrito no existe
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: this.t('cartnoExists'),
            })
            publish(EventName.OnFinish, null)
            return
        }
        
      let body = await this.mountBody()
        let result = await getPaymentToken(body,  this.publicToken, this.namePasarela, this.environment);
        if (result.success) {
           // window.location.href = result.data.payment_url
           this.history.push(this._at? 
             `/${this.publicToken}/main?at=${this._at}`
             :
             `/${this.publicToken}/main`)
           const isIOS = /^iP/.test(navigator.platform) || /^Mac/.test(navigator.platform) && navigator.maxTouchPoints > 4
           if (!isIOS) {
               window.open(result.data.payment_url, '_blank')
           } else {
               window.open(result.data.payment_url, '_self')
           }
           publish(EventName.OnFinish, null)
            
        } else {
            let message = '';
            switch (result.error_code) {
                case 1032:
                    message = this.t("invalidAPIKEY")
                    break
                case 1004: 
                    message = result.error_info
                    break
                case 1002:
                    message = this.t("moneyNoValid")
                    break
                case 1001:
                    message = this.t("amountNoValid")
                    break
                case 1000:
                    message = this.t("methodNotAvailable")
                    break
                case 1023:
                    message = this.t("gatewayNotAvailable")
                    break
                default:
                    message = `${this.t("errorduringPayment")} - ` + result.error_info
            }
            Sentry.captureException(new Error(`MultiSafePay - ${message}`));
            modalError(message, () => {
                publish(EventName.OnFinish, null)
                console.log('onIsConfirmed')
            });
        }
    }

    async mountBody() {
        const order_id = this.tokenTicket;
        const amount = this.totalAmount*100;
        const description = this.description;
        const url_post = this.url_post;
        const url_ok = this.url_ok;
        const url_ko = this.url_ko;
        const name = this.name;
        const surnames = this.surname;
        const phone = this.telephone;
        const email = this.email;
        const additionalInfo = this.additional_info;
        const money = this.money;
        const cart = this.cart;
        const publicToken = this.publicToken

        const tickets = [...cart.cartTickets, ...cart.cartPacketTickets, ...cart.cartCollaboratorTickets, ...cart.cartCollaboratorPacketTickets]
        let min_expired = 9999999999999; // Una cantidad muy elevada para luego ir comparando y obtener el menor tiempo
        for(let i = 0; i < tickets.length; i++) {
            let date_expire_at = new Date(tickets[i].expire_at)
            let ahora = new Date()
            let dif = (date_expire_at.getTime() - ahora.getTime())/1000
            if(dif < min_expired) {
                min_expired = dif;
            }
        }
    
        min_expired = Math.round(min_expired)

        let split_payments = await this.mountSplitPayments(cart)
        
        const custom2 = {}
        
        custom2['cartTickets'] = this.cart.cartTickets.map(cartTicket => cartTicket.numero)
        custom2['cartCollaboratorTickets'] = this.cart.cartCollaboratorTickets.map(cartTicket => cartTicket.numero)
        custom2['cartPacketTickets'] = this.cart.cartPacketTickets.map(cartTicket => cartTicket.numero)
        
        
        const custom_2 = JSON.stringify(custom2)
        
        if (custom_2.length >= 500) {
            throw new Error('custom_2 demasiado largo')
        }
        
        return JSON.stringify({
            type: "redirect", // Puede ser direct o redirect, direct: el cliente elige el metodo de pago antes y despues redirecciona y redirect primero redirecciona y luego se elige pago
            order_id: order_id,
            currency: money,
            amount: amount, // El amount ya está multiplicado por 100
            description: description,
            affiliate: {
                split_payments: split_payments
            },
            payment_options: { 
                notification_url: url_post, // Se llama con cualquier notificacion. Este hace la llamada al endpoint del server para generar los tickets
                notification_method: 'POST',
                redirect_url: url_ok, // Este abre la pagina de pago correcto que llama a la pagina que abre los tickets en pdf
                // cancel_url: url_ko, // Si se da a Cancelar, se redireccionará a la página del carrito
                // close_window: true 
            },
            gateway: 'CREDITCARD', //CREDITCARD For redirect requests using the generic CREDITCARD gateway, the payment page automatically detects the card scheme.
            customer: {
                locale: 'es_ES',
                first_name: name,
                last_name: surnames,
                phone: phone,
                email: email
            },
            custom_info: {
                custom_1: additionalInfo, // Es additionalInfo
                custom_2, // Si additionalInfo es mayor que 500, custom2 tendrá contenido
                custom_3: "" // Si custom2 es mayor que 500, custom3 tendrá contenido
            },
            seconds_active: min_expired > 0 ? min_expired : null,
            second_chance: {
                send_email: true
            }
        });
    }

    mountSplitPayments = async (cart) => {
        let split_affiliates = []
        const cartCollaboratorTickets = cart.cartCollaboratorTickets
        
        for(let i = 0; i < cartCollaboratorTickets.length; i++) {
            const operator_id = cartCollaboratorTickets[i].ticket.operator_id
            let error = false
            let merchant_collaborator = await getMerchantByIdTicando(this.publicToken, operator_id)
            if(merchant_collaborator.code === 50 && merchant_collaborator.msg === 'Error') {
                error = true
            }
            let payment_collaborator = {
                "merchant": merchant_collaborator.toString(),
                "fixed": cartCollaboratorTickets[i].ticket.totalNet * 100,
                "description": "Payment collaboratorTicket to collaborator id: "+operator_id
            }
            if(!error) {
                split_affiliates.push(payment_collaborator)
            }
        }

        const cartPacketTickets = cart.cartPacketTickets

        for(let i = 0; i < cartPacketTickets.length; i++) {
            const collaboratorTickets = cartPacketTickets[i].packetTicket.collaboratorTickets
            const lines = cartPacketTickets[i].packetTicket.lines
            for(let j = 0; j < collaboratorTickets.length; j++) {
                let totalCollaborator = 0
                let prices = collaboratorTickets[i].stretch.prices

                for(let k = 0; k < lines.length; k++) {
                    let category_id = lines[k].passengerCategory_id
                    let priceCategory = prices.filter(price => price.passengerCategory_id === category_id)

                    totalCollaborator = totalCollaborator + (priceCategory[0].packetPriceNet + priceCategory[0].packetPriceNet *collaboratorTickets[i].stretch.tax.value) * lines[k].quantity
                }

                const operator_id = collaboratorTickets[i].stretch.operator_id
                let merchant_collaborator = await getMerchantByIdTicando(this.publicToken, operator_id)
                let error = false;
                if(merchant_collaborator.code === 50 && merchant_collaborator.msg === 'Error') error = true
                let payment_collaborator = {
                    "merchant": merchant_collaborator.toString(),
                    "fixed": +totalCollaborator.toFixed(2) * 100,
                    "description": "Payment collaboratorTicket to collaborator id: "+operator_id
                }
                if(!error) split_affiliates.push(payment_collaborator)
            }
        }

        // Reparto ticando
        // let amount_ticando = (this.totalAmount*100) * 0.05
        // const merchantClient = await getMerchantByIdTicando(this.publicToken, null)
        let payment_ticando = {
            "merchant": process.env.REACT_APP_ENVIRONMENT === 'dev' ? '90274504' : '10751424', // ids de dev y prod de ticando
            "percentage": 5,//Math.round(amount_ticando),
            "description": `comision 5% ticando - ${cart.token}`
        }

        split_affiliates.push(payment_ticando)
        return split_affiliates
    }

}