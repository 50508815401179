import React from 'react';

export const PagoIncorrecto: React.FC = () => {
    return(
        <div className="page-wrapper animated fadeIn">
            <header className="book-embed-nav with-padding respond-print-hide">
                <div className="ben-right">
                    <ul className="ben-left">
                        <li className="ben-flyout-wrap">
                            <button className="ben-item ben-secure-button fh-green" type="button"
                                    aria-expanded="false">
                                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"
                                     id="icon-svg-lock" className="icon-svg-lock" width="11" height="14"
                                     viewBox="0 0 11.02 14" ng-svg="icon-svg-lock">
                                    <path d="M7.66,3.88a2.15,2.15,0,0,0-4.3,0v2h4.3Z" style={{fill: 'none'}}></path>
                                    <path
                                        d="M9.39,5.85v-2a3.88,3.88,0,0,0-7.76,0v2A1.72,1.72,0,0,0,0,7.56v4.73A1.72,1.72,0,0,0,1.71,14h7.6A1.72,1.72,0,0,0,11,12.29V7.56A1.71,1.71,0,0,0,9.39,5.85Zm-6-2a2.15,2.15,0,0,1,4.3,0v2H3.36Z"></path>
                                </svg>
                                Pago Seguro
                            </button>

                        </li>

                        <li className="ben-flyout-wrap">

                            <div>
                                <a className="ben-item ben-cart-button fade-in-up" type="button"
                                   aria-expanded="false" href="/cart">
                                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" id="icon-cart"
                                         className="icon-cart" width="27" height="23" viewBox="0 0 27 23" style={{fill: 'white'}}
                                    >
                                        <path
                                            d="M24.47,16.68H7.12l-3.74-14H.08V.21H5.42l3.74,14H22.31l1.58-8.48H11.25V3.22H26.92Z"></path>
                                        <path d="M23,20.59a2.2,2.2,0,1,0-2.2,2.2A2.19,2.19,0,0,0,23,20.59Z"></path>
                                        <path d="M12.19,20.59A2.2,2.2,0,1,0,10,22.79,2.19,2.19,0,0,0,12.19,20.59Z"></path>
                                    </svg>
                                    <span className="respond-handhelds-hide">Carrito</span>
                                    <span className="respond-desktop-hide"></span>
                                </a>
                            </div>

                        </li>
                    </ul>
                    <div className="ben-flyout-wrap" ng-if="lightframe.isLightframe() || backUrl">
                        <button className="ben-item ben-close-button test-toggle-leave-flyout-action" type="button"
                                aria-expanded="false">
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" id="icon-x"
                                 className="icon-x" width="14" height="14" viewBox="0 0 14 14" >
                                <polygon
                                    points="14 1.75 12.25 0 7 5.25 1.75 0 0 1.75 5.25 7 0 12.25 1.75 14 7 8.75 12.25 14 14 12.25 8.75 7 14 1.75"></polygon>
                            </svg>
                            <span className="visually-hidden">Cerrar</span>
                        </button>

                    </div>
                </div>

            </header>
            <section className="page-header tour-two tour-list destinations-details text-center">
                <div className="container">
                        <div className="tour-sidebar">
                            <div className="tour-sidebar__featured pagoIncorrecto">

                                <div className="svg-box">
                                    <svg className="circular red-stroke">
                                        <circle className="path" cx="75" cy="75" r="50" fill="none" strokeMiterlimit={10} strokeWidth={5}/>
                                    </svg>
                                    <svg className="cross red-stroke">
                                        <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-502.652,-204.518)">
                                            <path className="first-line" d="M634.087,300.805L673.361,261.53"
                                                  fill="none"/>
                                        </g>
                                        <g transform="matrix(-1.28587e-16,-0.79961,0.79961,-1.28587e-16,-204.752,543.031)">
                                            <path className="second-line" d="M634.087,300.805L673.361,261.53"/>
                                        </g>
                                    </svg>
                                </div>
                                <h1>Pago incorrecto</h1>
                                <div>
                                    Hemos obtenido un error en el proceso de la compra. Vuelva a intentarlo.
                                </div>
                            </div>
                        </div>
                </div>
            </section>
            <footer className="book-embed-footer">
                <div className="book-embed-footer-left">
                    <div>
                        <div className="ben-translate-toggle respond-print-hide">
                            <button className="ben-item ben-translate-button capitalize" type="button"
                                    aria-describedby="translate-btn-describe"
                                    aria-expanded="false">
                                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" id="icon-globe"
                                     className="icon-globe" width="14" height="14" viewBox="0 0 14 14"
                                     ng-svg="icon-globe">
                                    <path
                                        d="M14 7a7 7 0 11-7-7 7 7 0 017 7zM4 7.51v1h2.41c.41 0 .59.19.59.6v1.81c0 .91.14.74-.67 1.34l-.76.58a6.09 6.09 0 005.3-1.24l-.17-.19-1.48-1.48A.65.65 0 019 9.41V7.62c0-.11-.46 0-.46-.12h-.86c-.41 0-.59-.19-.59-.59V5.66A.67.67 0 017.46 5c1.07-.67 2.54-1.35 3.62-2l.24-.15-.83-.72-2.62 1.72a.53.53 0 01-.81-.08L4.88 1.58c-.1-.09-.17-.13-.3-.07a6.13 6.13 0 00-2.64 2.27 2 2 0 00-.12.22h3.56c.44 0 .62.18.62.62v2.29c0 .4-.18.58-.58.59zm1-1V5H1.55a.24.24 0 00-.27.2 6 6 0 003 7.16.21.21 0 00.28 0 9.64 9.64 0 011-.71.87.87 0 00.44-.97 11.76 11.76 0 010-1.19H3.62c-.44 0-.62-.17-.62-.61V7.09c0-.4.18-.58.58-.59zm6.58 4.36a5.89 5.89 0 00.36-7.22c-1.11.7-2.64 1.4-3.76 2.1a.2.2 0 00-.08.12v.63h1.33a.51.51 0 01.57.58v2.05a.35.35 0 00.07.23zm-4-8l1.93-1.31a6 6 0 00-3.64-.45c.57.58 1.13 1.15 1.72 1.73z"></path>
                                </svg>
                                Espa&ntilde;ol (ES)
                            </button>

                        </div>
                    </div>

                </div>
                <div className="book-embed-footer-right">
                    <img src="https://res.cloudinary.com/marketingpyme/image/upload/w_160,h_45,c_fill/logo_ticando.png" className="img-fluid __web-inspector-hide-shortcut__" alt="CIVITRIP" />
                </div>
            </footer>
        </div>
    );
}
