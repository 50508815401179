export default class PassengerCategory {
    id: number
    name: string
    code: string
    description: string
    type: string
    disabled_at: string
    order: number
    typePrice?: TypePrice
    minToReserve?: any
    maxToReserve?: number
}
export const TypePrice = {
    byPassenger: 'byPassenger', // por pasagero
    byGroup: 'byGroup', // precio fijo independientemente de los pasajeros que vayan
}

export type TypePrice = string
