import { FormGroup, FormControlLabel, Checkbox } from "@material-ui/core"
import { useInitIdioma } from "customHooks/useInitIdioma"

export const CheckBoxTerms = ({publicToken, client, checked, handleChangeCheked, existsTerms, clickTerminos}) => {

    const {t} = useInitIdioma(publicToken) // Custom Hook


    return ( client ? 
        <FormGroup style={{marginTop: 20}}>
            {
                !!JSON.parse(client.terms)?.urlTerms && 
                <FormControlLabel
                    control={ <Checkbox checked={checked} onChange={handleChangeCheked}
                            required={true} inputProps={{'aria-label': 'controlled'}}/>}
                    label={
                        <span>
                            <span style={{color: 'red'}}>*</span>
                            {t("acceptTermsI")}
                            <a rel='noopener noreferrer' href={JSON.parse(client?.terms)?.urlTerms} target='_blank'>
                                {t("acceptTermsII")}
                            </a>
                            {t("acceptTermsIII")}
                        </span>
                    }
                />
            }

            {!!!JSON.parse(client.terms)?.urlTerms && <FormControlLabel 
                control={<Checkbox checked={checked} onChange={handleChangeCheked} required={true} inputProps={{ 'aria-label': 'controlled' }} />}  
                label={ existsTerms ? 
                    <span>
                        <span style={{color:'red'}}>*</span>
                        {t("acceptTermsI")}
                        <span onClick={()=>clickTerminos()} style={{textDecoration: 'underline',color:'blue'}}>
                            {t("acceptTermsII")}
                        </span> 
                        {t("acceptTermsIII")}
                    </span> 
                    : 
                    <span>
                        <span style={{color:'red'}}>*</span>
                        <span>{t("acceptTermsIV")}</span>
                    </span>
                }  
            />}
        </FormGroup>
        : null
    )
}



