import { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import './stripe_docs.css'
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

interface Props {
  publicToken?: any
  setOpenStripe?:()=>void
  url_post?: any
  totalAmount?: any
  money?: any
  cart?: any
  t?: any
}

export default function CheckoutForm(props: Props) {
  let {publicToken, setOpenStripe, totalAmount, money, cart, t} = props
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }
  }, [stripe]);

  const handleSubmit = async (e) => {
    let i = 0;
    let expired = false;
    const tickets = [...cart.cartTickets, ...cart.cartCollaboratorTickets, ...cart.cartPacketTickets, ...cart.cartCollaboratorPacketTickets]
    while(i < tickets.length && !expired) {
        if(tickets[i].expire_at !== null) {
            if(new Date(tickets[i].expire_at) < new Date()) { // Ha expirado
                expired = true
            }
        }else {
            const treintaMins = new Date(tickets[i].created_at);
            treintaMins.setSeconds(treintaMins.getSeconds()+30*60)
            if(treintaMins < new Date()){
              expired = true
            }
        }
        i++;
    }

    if(expired) {
      return
    }

    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    let return_url;
    if(process.env.REACT_APP_ENVIRONMENT === 'dev'){
      return_url = `https://${process.env.REACT_APP_SUBDOMINIO_DEV}/${publicToken}/payment/processing/`
    }else if(process.env.REACT_APP_ENVIRONMENT === 'prod'){
      return_url = `https://${process.env.REACT_APP_SUBDOMINIO_PROD}/${publicToken}/payment/processing/`
    }
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: return_url,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsLoading(false);
  };

  return (
    <form id="form_stripe" onSubmit={handleSubmit}>
      <IconButton style={{paddingLeft:450}} onClick={()=>setOpenStripe()}>
        <CloseIcon />
      </IconButton>
      <PaymentElement id="payment-element" />
      <button className="buttonStripe" disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div  className="spinner" id="spinner"></div> : `${t("pay")} ${totalAmount} ${money}`}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div style={{color: 'red'}} id="payment-message">{message}</div>}
    </form>
  );
}