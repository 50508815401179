import { useInitIdioma } from "customHooks/useInitIdioma";

interface Props {
    busqueda?: string
    setBusqueda?: any
    setLoaded?: any
    publicToken: any
}

export const SearchExperience = ({busqueda, setBusqueda, setLoaded, publicToken}: Props) => {
    
    const {t} = useInitIdioma(publicToken) // Custom Hook

    const handleChange = (e) => {
        setLoaded(false)
        setBusqueda(e.target.value)
    }
   
    return (
        <div className="containerInput">
            <div className="row">
                <div className="col-md-6">
                    <input style={{width: '300px',height: '10px',paddingTop:'18px', paddingLeft: '10px', paddingBottom: '18px'}}
                    className="form-control inputBuscar"
                    value={busqueda}
                    placeholder={t("searchExperience")}
                    onChange={handleChange}/>
                </div>
            </div>
        </div>
    )
}