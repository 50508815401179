import React from 'react'

export const ItemRow = ({ data, index, onAdd, onRemove }) => {
  return (
    <div
      key={data.name}
      className="itemRow">
      <div className="itemRow-name">
        <div>
          <div>{data.name}</div>
          <div className="text_x-small">{data.description}</div>
        </div>
        <div className="itemRow-basePrice">{data.basePrice}</div>
      </div>
      <div className="itemRow-details">
        <div className="itemRow-actions">
          <div className="itemRow-buttonAction">
            <button value={index} onClick={onRemove}>-</button>
          </div>
          <div className="itemRow-action-quantity">{data.quantity}</div>
          <div className="itemRow-buttonAction">
            <button value={index} onClick={onAdd}>+</button>
          </div>
        </div>
        <div className="itemRow-total">{data.total}</div>
      </div>
    </div>
  );
};