import React from 'react'
import {Button} from 'reactstrap'
import {Languages, useInitIdioma} from '../customHooks/useInitIdioma'

interface Props {
  languages: string[]
  languageSelected: string
  onClick(lang): void
}

export const LanguageButtons = ({languages, languageSelected, onClick}: Props) => {

  const {t} = useInitIdioma()
  
  return (
    <div
      className="cont-btn-language"
    >
      {
        languages?.length>0 && languages.map(lang => {
          return (
            <Button
              key={lang}
              className={`ben-item ben-translate-button capitalize ${lang !== languageSelected? 'no-selected' : 'btn-active'} btn-language `}
              onClick={() => {
                onClick(lang)
              }}
            >
              {`${t(Languages[lang])}`}
            </Button>
          )
        })
      }
    </div>
  )
}