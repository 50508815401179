export abstract class PaymentGateway {
    protected _at: string
    pasarelaPago: string;
    setAt(at) {
       this._at = at 
    }
    abstract pay(): Promise<void> | Promise<any>;
    abstract mountBody(): any;
    abstract set_url_post(any): void; // Url del endpoint que se llama cuando se hace el pago
    abstract set_url_ok(any): void; // Url a la que se redirige cuando se hace el pago (muestra el pdf)
    abstract set_url_ko(any): void; // Url que aparece cuando hay error en el pago
  }
