export const currencies = [
    {
        alphabeticCode: "ALL",
        numericCode: 8
    },
    {
        alphabeticCode: "AMD",
        numericCode: 51
    },
    {
        alphabeticCode: "ANG",
        numericCode: 532
    },
    {
        alphabeticCode: "AOA",
        numericCode: 973
    },
    {
        alphabeticCode: "ARS",
        numericCode: 32
    },
    {
        alphabeticCode: "KES",
        numericCode: 404
    },
    {
        alphabeticCode: "KGS",
        numericCode: 417
    },
    {
        alphabeticCode: "KHR",
        numericCode: 116
    },
    {
        alphabeticCode: "KMF",
        numericCode: 174
    },
    {
        alphabeticCode: "KPW",
        numericCode: 408
    },
    {
        alphabeticCode: "KRW",
        numericCode: 410
    },
    {
        alphabeticCode: "KWD",
        numericCode: 414
    },
    {
        alphabeticCode: "KYD",
        numericCode: 136
    },
    {
        alphabeticCode: "KZT",
        numericCode: 398
    },
    {
        alphabeticCode: "LAK",
        numericCode: 418
    },
    {
        alphabeticCode: "LBP",
        numericCode: 422
    },
    {
        alphabeticCode: "LKR",
        numericCode: 144
    },
    {
        alphabeticCode: "LRD",
        numericCode: 430
    },
    {
        alphabeticCode: "LSL",
        numericCode: 426
    },
    {
        alphabeticCode: "LYD",
        numericCode: 434
    },
    {
        alphabeticCode: "MAD",
        numericCode: 504
    },
    {
        alphabeticCode: "MDL",
        numericCode: 498
    },
    {
        alphabeticCode: "MGA",
        numericCode: 969
    },
    {
        alphabeticCode: "MKD",
        numericCode: 807
    },
    {
        alphabeticCode: "MMK",
        numericCode: 104
    },
    {
        alphabeticCode: "MNT",
        numericCode: 496
    },
    {
        alphabeticCode: "MOP",
        numericCode: 446
    },
    {
        alphabeticCode: "MRU",
        numericCode: 929
    },
    {
        alphabeticCode: "MUR",
        numericCode: 480
    },
    {
        alphabeticCode: "MVR",
        numericCode: 462
    },
    {
        alphabeticCode: "MWK",
        numericCode: 454
    },
    {
        alphabeticCode: "MXN",
        numericCode: 484
    },
    {
        alphabeticCode: "MYR",
        numericCode: 458
    },
    {
        alphabeticCode: "MZN",
        numericCode: 943
    },
    {
        alphabeticCode: "NAD",
        numericCode: 516
    },
    {
        alphabeticCode: "NGN",
        numericCode: 566
    },
    {
        alphabeticCode: "NIO",
        numericCode: 558
    },
    {
        alphabeticCode: "NOK",
        numericCode: 578
    },
    {
        alphabeticCode: "NPR",
        numericCode: 524
    },
    {
        alphabeticCode: "PEN",
        numericCode: 604
    },
    {
        alphabeticCode: "USD",
        numericCode: 840
    },
    {
        alphabeticCode: "NZD",
        numericCode: 554
    },
    {
        alphabeticCode: "OMR",
        numericCode: 512
    },
    {
        alphabeticCode: "PAB",
        numericCode: 590
    },
    {
        alphabeticCode: "PGK",
        numericCode: 598
    },
    {
        alphabeticCode: "PHP",
        numericCode: 608
    },
    {
        alphabeticCode: "PKR",
        numericCode: 586
    },
    {
        alphabeticCode: "PLN",
        numericCode: 985
    },
    {
        alphabeticCode: "PYG",
        numericCode: 600
    },
    {
        alphabeticCode: "CHW",
        numericCode: 948
    },
    {
        alphabeticCode: "SBD",
        numericCode: 90
    },
    {
        alphabeticCode: "SCR",
        numericCode: 690
    },
    {
        alphabeticCode: "SDG",
        numericCode: 938
    },
    {
        alphabeticCode: "SEK",
        numericCode: 752
    },
    {
        alphabeticCode: "SGD",
        numericCode: 702
    },
    {
        alphabeticCode: "SHP",
        numericCode: 654
    },
    {
        alphabeticCode: "SLL",
        numericCode: 694
    },
    {
        alphabeticCode: "SOS",
        numericCode: 706
    },
    {
        alphabeticCode: "SRD",
        numericCode: 968
    },
    {
        alphabeticCode: "SSP",
        numericCode: 728
    },
    {
        alphabeticCode: "STN",
        numericCode: 930
    },
    {
        alphabeticCode: "SVC",
        numericCode: 222
    },
    {
        alphabeticCode: "SYP",
        numericCode: 760
    },
    {
        alphabeticCode: "SZL",
        numericCode: 748
    },
    {
        alphabeticCode: "THB",
        numericCode: 764
    },
    {
        alphabeticCode: "TJS",
        numericCode: 972
    },
    {
        alphabeticCode: "TMT",
        numericCode: 934
    },
    {
        alphabeticCode: "TND",
        numericCode: 788
    },
    {
        alphabeticCode: "TOP",
        numericCode: 776
    },
    {
        alphabeticCode: "TRY",
        numericCode: 949
    },
    {
        alphabeticCode: "TTD",
        numericCode: 780
    },
    {
        alphabeticCode: "TWD",
        numericCode: 901
    },
    {
        alphabeticCode: "TZS",
        numericCode: 834
    },
    {
        alphabeticCode: "UAH",
        numericCode: 980
    },
    {
        alphabeticCode: "UGX",
        numericCode: 800
    },
    {
        alphabeticCode: "QAR",
        numericCode: 634
    },
    {
        alphabeticCode: "RON",
        numericCode: 946
    },
    {
        alphabeticCode: "RSD",
        numericCode: 941
    },
    {
        alphabeticCode: "RUB",
        numericCode: 643
    },
    {
        alphabeticCode: "AUD",
        numericCode: 36
    },
    {
        alphabeticCode: "AWG",
        numericCode: 533
    },
    {
        alphabeticCode: "AZN",
        numericCode: 944
    },
    {
        alphabeticCode: "BAM",
        numericCode: 977
    },
    {
        alphabeticCode: "BBD",
        numericCode: 52
    },
    {
        alphabeticCode: "BGN",
        numericCode: 975
    },
    {
        alphabeticCode: "BHD",
        numericCode: 48
    },
    {
        alphabeticCode: "BIF",
        numericCode: 108
    },
    {
        alphabeticCode: "BMD",
        numericCode: 60
    },
    {
        alphabeticCode: "BND",
        numericCode: 96
    },
    {
        alphabeticCode: "BOB",
        numericCode: 68
    },
    {
        alphabeticCode: "BRL",
        numericCode: 986
    },
    {
        alphabeticCode: "BSD",
        numericCode: 44
    },
    {
        alphabeticCode: "BTN",
        numericCode: 64
    },
    {
        alphabeticCode: "BWP",
        numericCode: 72
    },
    {
        alphabeticCode: "BYN",
        numericCode: 933
    },
    {
        alphabeticCode: "BZD",
        numericCode: 84
    },
    {
        alphabeticCode: "CAD",
        numericCode: 124
    },
    {
        alphabeticCode: "CDF",
        numericCode: 976
    },
    {
        alphabeticCode: "CHF",
        numericCode: 756
    },
    {
        alphabeticCode: "CLP",
        numericCode: 152
    },
    {
        alphabeticCode: "CNY",
        numericCode: 156
    },
    {
        alphabeticCode: "COP",
        numericCode: 170
    },
    {
        alphabeticCode: "CRC",
        numericCode: 188
    },
    {
        alphabeticCode: "CUC",
        numericCode: 931
    },
    {
        alphabeticCode: "CUP",
        numericCode: 192
    },
    {
        alphabeticCode: "CVE",
        numericCode: 132
    },
    {
        alphabeticCode: "CZK",
        numericCode: 203
    },
    {
        alphabeticCode: "DJF",
        numericCode: 262
    },
    {
        alphabeticCode: "DKK",
        numericCode: 208
    },
    {
        alphabeticCode: "DOP",
        numericCode: 214
    },
    {
        alphabeticCode: "DZD",
        numericCode: 12
    },
    {
        alphabeticCode: "EGP",
        numericCode: 818
    },
    {
        alphabeticCode: "ERN",
        numericCode: 232
    },
    {
        alphabeticCode: "ETB",
        numericCode: 230
    },
    {
        alphabeticCode: "RWF",
        numericCode: 646
    },
    {
        alphabeticCode: "SAR",
        numericCode: 682
    },
    {
        alphabeticCode: "FJD",
        numericCode: 242
    },
    {
        alphabeticCode: "FKP",
        numericCode: 238
    },
    {
        alphabeticCode: "GBP",
        numericCode: 826
    },
    {
        alphabeticCode: "GEL",
        numericCode: 981
    },
    {
        alphabeticCode: "GHS",
        numericCode: 936
    },
    {
        alphabeticCode: "GIP",
        numericCode: 292
    },
    {
        alphabeticCode: "GMD",
        numericCode: 270
    },
    {
        alphabeticCode: "GNF",
        numericCode: 324
    },
    {
        alphabeticCode: "GTQ",
        numericCode: 320
    },
    {
        alphabeticCode: "GYD",
        numericCode: 328
    },
    {
        alphabeticCode: "HKD",
        numericCode: 344
    },
    {
        alphabeticCode: "HNL",
        numericCode: 340
    },
    {
        alphabeticCode: "HRK",
        numericCode: 191
    },
    {
        alphabeticCode: "HTG",
        numericCode: 332
    },
    {
        alphabeticCode: "HUF",
        numericCode: 348
    },
    {
        alphabeticCode: "IDR",
        numericCode: 360
    },
    {
        alphabeticCode: "ILS",
        numericCode: 376
    },
    {
        alphabeticCode: "INR",
        numericCode: 356
    },
    {
        alphabeticCode: "IQD",
        numericCode: 368
    },
    {
        alphabeticCode: "IRR",
        numericCode: 364
    },
    {
        alphabeticCode: "ISK",
        numericCode: 352
    },
    {
        alphabeticCode: "JMD",
        numericCode: 388
    },
    {
        alphabeticCode: "JOD",
        numericCode: 400
    },
    {
        alphabeticCode: "JPY",
        numericCode: 392
    },
    {
        alphabeticCode: "UYI",
        numericCode: 940
    },
    {
        alphabeticCode: "UYU",
        numericCode: 858
    },
    {
        alphabeticCode: "UYW",
        numericCode: 927
    },
    {
        alphabeticCode: "UZS",
        numericCode: 860
    },
    {
        alphabeticCode: "VED",
        numericCode: 926
    },
    {
        alphabeticCode: "VES",
        numericCode: 928
    },
    {
        alphabeticCode: "VND",
        numericCode: 704
    },
    {
        alphabeticCode: "VUV",
        numericCode: 548
    },
    {
        alphabeticCode: "WST",
        numericCode: 882
    },
    {
        alphabeticCode: "XAF",
        numericCode: 950
    },
    {
        alphabeticCode: "XAG",
        numericCode: 961
    },
    {
        alphabeticCode: "XAU",
        numericCode: 959
    },
    {
        alphabeticCode: "XBA",
        numericCode: 955
    },
    {
        alphabeticCode: "XBB",
        numericCode: 956
    },
    {
        alphabeticCode: "XBC",
        numericCode: 957
    },
    {
        alphabeticCode: "XBD",
        numericCode: 958
    },
    {
        alphabeticCode: "XCD",
        numericCode: 951
    },
    {
        alphabeticCode: "AFN",
        numericCode: 971
    },
    {
        alphabeticCode: "BDT",
        numericCode: 50
    },
    {
        alphabeticCode: "XDR",
        numericCode: 960
    },
    {
        alphabeticCode: "XOF",
        numericCode: 952
    },
    {
        alphabeticCode: "XPD",
        numericCode: 964
    },
    {
        alphabeticCode: "XPF",
        numericCode: 953
    },
    {
        alphabeticCode: "XPT",
        numericCode: 962
    },
    {
        alphabeticCode: "XSU",
        numericCode: 994
    },
    {
        alphabeticCode: "XTS",
        numericCode: 963
    },
    {
        alphabeticCode: "XUA",
        numericCode: 965
    },
    {
        alphabeticCode: "XXX",
        numericCode: 999
    },
    {
        alphabeticCode: "YER",
        numericCode: 886
    },
    {
        alphabeticCode: "ZAR",
        numericCode: 710
    },
    {
        alphabeticCode: "ZMW",
        numericCode: 967
    },
    {
        alphabeticCode: "ZWL",
        numericCode: 932
    },
    {
        alphabeticCode: "CLF",
        numericCode: 990
    },
    {
        alphabeticCode: "COU",
        numericCode: 970
    },
    {
        alphabeticCode: "BOV",
        numericCode: 984
    },
    {
        alphabeticCode: "USN",
        numericCode: 997
    },
    {
        alphabeticCode: "CHE",
        numericCode: 947
    },
    {
        alphabeticCode: "MXV",
        numericCode: 979
    },
    {
        alphabeticCode: "AED",
        numericCode: 784
    },
    {
        alphabeticCode: "EUR",
        numericCode: 978
    },
]

