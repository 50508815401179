import React from 'react';
import { useHistory } from 'react-router';
import Stretch from '../../models/Stretch'
import CollaboratorStretch from '../../models/CollaboratorStretch'
import {BasePacket, Packet} from '../../models/Packet'
import CollaboratorPacket from '../../models/CollaboratorPacket'

interface CardStretchProps {
    publicToken: string
    at?: string
    stretch: Stretch,
    imagen: string,
    id_operationLine: number,
    money: string
    t: any
    isGreenpay?: boolean
    monedasGreenpay?: any
}

export const CardStretch: React.FC<CardStretchProps> = (props) => {
    const {publicToken, at, stretch, imagen, id_operationLine, money, t, isGreenpay, monedasGreenpay} = props
    const history = useHistory();

    const openTrayecto = () => {
        history.push(at? 
          `/${publicToken}/trayecto/${stretch.id}/${id_operationLine}?at=${at}`
          : 
          `/${publicToken}/trayecto/${stretch.id}/${id_operationLine}`
        );
    }

    return(
        <DetailStretch stretch={stretch} onClick={openTrayecto} imagen={imagen.replace("w_250,h_200,c_fill","w_450,h_350,c_fill")} publicToken={publicToken} money={money} t={t} isGreenpay={isGreenpay} monedasGreenpay={monedasGreenpay}/>
    );
}

interface CardCollaboratorStretchProps {
    publicToken: string
    at?: string
    collaboratorStretch: CollaboratorStretch
    image: string
    money: string
    t: any
    isGreenpay?: boolean
    monedasGreenpay?: any
}


export const CardCollaboratorStretch = (props: CardCollaboratorStretchProps) => {
    const {publicToken, at, collaboratorStretch, image, money, t, isGreenpay, monedasGreenpay} = props
    const history = useHistory();
    const openCollaboratorStretch = () => {
        history.push(at? 
          `/${publicToken}/cs/${collaboratorStretch.id}/${collaboratorStretch.operator_id}?at=${at}` 
          : 
          `/${publicToken}/cs/${collaboratorStretch.id}/${collaboratorStretch.operator_id}`);
    }

    return (
        <DetailStretch stretch={collaboratorStretch} onClick={openCollaboratorStretch} imagen={image.replace("w_250,h_200,c_fill","w_450,h_350,c_fill")} publicToken={publicToken} money={money} t={t} isGreenpay={isGreenpay} monedasGreenpay={monedasGreenpay}/>
    )
}

const DetailStretch = ({stretch, onClick, imagen, publicToken, money, t, isGreenpay, monedasGreenpay}: {stretch: Stretch | CollaboratorStretch, onClick: ()=>void, imagen: string, publicToken: string, money: string, t, isGreenpay?, monedasGreenpay?}) => {
    const moneda = stretch.primaryMoney ? stretch.primaryMoney.alphabeticCode : 'nomoneda';
    if(money !== null && money !== moneda){
        onClick = ()=>{}
    }


    let monedaValida;
    if(isGreenpay && monedasGreenpay){
        if(monedasGreenpay.includes(moneda)){
            monedaValida = true;
        }else{
            monedaValida = false;
            onClick = ()=>{}
        }
    }

    return(
        <div style={{padding: '15px'}} onClick={onClick}>
            {
                money === null && !isGreenpay &&
                <div className="destinations-three__single" style={{backgroundImage: `url(${imagen})` }}>
                <div className="destinations-three__content">
                    <h3><a>{stretch.name}</a></h3>
                </div>
                
                    <div className="destinations-three__hover-content">
                        <h3><a onClick={onClick}>{stretch.name}</a></h3>
                        <p>{stretch.description}</p>
                        <a className="destinations-three__link"><i className="fa fa-arrow-right"/></a>
                    </div>
                
            </div>
            }
            {
                money === null && isGreenpay && monedaValida &&
                <div className="destinations-three__single" style={{backgroundImage: `url(${imagen})` }}>
                <div className="destinations-three__content">
                    <h3><a>{stretch.name}</a></h3>
                </div>
                

                    <div className="destinations-three__hover-content">
                        <h3><a onClick={onClick}>{stretch.name}</a></h3>
                        <p>{stretch.description}</p>
                        <a className="destinations-three__link"><i className="fa fa-arrow-right"/></a>
                    </div>
                
            </div> 
            }
            {
                money === null && isGreenpay && !monedaValida &&
                <div className="destinations-three__single" style={{opacity:'0.4', backgroundImage: `url(${imagen})` }}>
                <div className="destinations-three__content">
                    <h3><a style = {{color:'orange'}}>{stretch.name}</a></h3>
                </div>
                

                    <div className="destinations-three__hover-content">
                        <h3><a onClick={()=>{}} style = {{color:'orange'}}>{stretch.name}</a></h3>
                        <p>{t("moneyGreenpay")}</p>
                    </div>

            </div>
            }

            {
                money !== null && money === moneda &&
            <div className="destinations-three__single" style={{backgroundImage: `url(${imagen})` }}>
                <div className="destinations-three__content">
                    <h3><a>{stretch.name}</a></h3>
                </div>
                

                    <div className="destinations-three__hover-content">
                        <h3><a onClick={onClick}>{stretch.name}</a></h3>
                        <p>{stretch.description}</p>
                        <a className="destinations-three__link"><i className="fa fa-arrow-right"/></a>
                    </div>
                
            </div>
            }
            {
                money !== null && money !== moneda &&
            <div className="destinations-three__single" style={{opacity:'0.4', backgroundImage: `url(${imagen})` }}>
                <div className="destinations-three__content">
                    <h3><a style = {{color:'orange'}}>{stretch.name}</a></h3>
                </div>
                

                    <div className="destinations-three__hover-content">
                        <h3><a onClick={()=>{}} style = {{color:'orange'}}>{stretch.name}</a></h3>
                        <p>{t("differentMoneymsg")}
                        </p>
                    </div>
                

                {
                    money === moneda &&
                    <p> HAY MONEDA EURO</p>
                }
                {
                    money !== moneda &&
                    <p> {moneda}</p>
                }
            </div>
            }
            
        </div>
    );
}

interface CardPacketProps {
    publicToken: string
    packet: BasePacket
    image: string
    isCollaborator: boolean
    money: string
    t: any
    isGreenpay?: boolean
    monedasGreenpay?: any
}

export const CardPacket = ({publicToken, packet, image, isCollaborator, money, t, isGreenpay, monedasGreenpay}: CardPacketProps) => {
    const history = useHistory();
    const moneda = packet.primaryMoney ? packet.primaryMoney.alphabeticCode : 'nomoneda';

    let openPacket = () => {
        if(money !== null && money !== moneda){
            return ()=>{}
        }
        if (isCollaborator) {
            const tempPacket =  new CollaboratorPacket(packet as CollaboratorPacket)
            return history.push(`/${publicToken}/cp/${tempPacket.id}/${tempPacket.operator_id}`)
        }
        const tempPacket = new Packet(packet as Packet)
        history.push(`/${publicToken}/packet/${tempPacket.id}/${tempPacket.operationLine_id}`);
    }
    let monedaValida;
    if(isGreenpay && monedasGreenpay){
        if(monedasGreenpay.includes(moneda)){
            monedaValida = true;
        }else{
            monedaValida = false;
            openPacket = () => {
                return ()=>{}
            }       
        }
    }
    return(
        <div style={{padding: '15px'}} onClick={openPacket}>
            {
                money === null && !isGreenpay &&
                <div className="destinations-three__single"style={{ backgroundImage: `url(${image})` }}>
                    <div className="destinations-three__content">
                        <h3><a>{packet.name}</a></h3>
                    </div>

                    <div className="destinations-three__hover-content">
                        <h3><a onClick={openPacket}>{packet.name}</a></h3>
                        <p>{packet.description}</p>
                        <a className="destinations-three__link"><i className="fa fa-arrow-right"/></a>
                    </div>
                </div>                
            }
            {
                money === null && isGreenpay && monedaValida &&
                <div className="destinations-three__single"style={{ backgroundImage: `url(${image})` }}>
                <div className="destinations-three__content">
                    <h3><a>{packet.name}</a></h3>
                </div>

                <div className="destinations-three__hover-content">
                    <h3><a onClick={openPacket}>{packet.name}</a></h3>
                    <p>{packet.description}</p>
                    <a className="destinations-three__link"><i className="fa fa-arrow-right"/></a>
                </div>
            </div>                   
            }
            {
                money === null && isGreenpay && !monedaValida &&
                <div className="destinations-three__single"style={{ opacity: '0.4', backgroundImage: `url(${image})` }}>
                <div className="destinations-three__content">
                    <h3><a style = {{color:'orange'}}>{packet.name}</a></h3>
                </div>

                <div className="destinations-three__hover-content">
                    <h3><a onClick={openPacket} style = {{color:'orange'}}>{packet.name}</a></h3>
                     <p>{t("moneyGreenpay")}</p>
                </div>
            </div>                

            }
            {
                 money !== null && money === moneda &&
                 <div className="destinations-three__single"style={{ backgroundImage: `url(${image})` }}>
                    <div className="destinations-three__content">
                        <h3><a>{packet.name}</a></h3>
                    </div>

                    <div className="destinations-three__hover-content">
                        <h3><a onClick={openPacket}>{packet.name}</a></h3>
                        <p>{packet.description}</p>
                        <a className="destinations-three__link"><i className="fa fa-arrow-right"/></a>
                    </div>
                </div>                
            }
            {
                money !== null && money !== moneda &&
                <div className="destinations-three__single"style={{ opacity: '0.4', backgroundImage: `url(${image})` }}>
                <div className="destinations-three__content">
                    <h3><a style = {{color:'orange'}}>{packet.name}</a></h3>
                </div>

                <div className="destinations-three__hover-content">
                    <h3><a onClick={openPacket} style = {{color:'orange'}}>{packet.name}</a></h3>
                     <p>{t("differentMoneymsg")}</p>
                </div>
            </div> 
            }


        </div>

    );
}


