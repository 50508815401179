import Cart from "models/Cart";
import { isCartCollaboratorPacketTicket, isCartCollaboratorTicket, isCartPacketTicket, isCartTicket } from "Modules/Cart/CartItem";
import { PaymentGateway } from "./paymentGateway";
import { getPaymentToken, modalError } from "./Request";
import * as Sentry from "@sentry/react";

const environment = process.env.REACT_APP_ENVIRONMENT

export  class Greenpay extends PaymentGateway{

  set_url_post(any: any): void {
    throw new Error("Method not implemented.");
  }
  set_url_ok(any: any): void {
    throw new Error("Method not implemented.");
  }
  set_url_ko(any: any): void {
    throw new Error("Method not implemented.");
  }
  publicToken: string;
  urlBasePost: string;
  namePasarela: string;
  totalAmount: number;
  money: string;
  tokenTicket: string;
  name: string;
  surname: string;
  telephone: string;
  email: string;
  cart: Cart;
  additional_info: any;
  t: any;

  constructor(tokenTicket:string, publicToken: string,  totalAmount: number, description: string, name: string, surname: string, telephone: string, email: string, additional_info: any, money: string, cart: Cart,t: any) {
    super();
    this.publicToken = publicToken;
    this.namePasarela = 'GREENPAY';
    this.totalAmount = totalAmount;
    this.money = money;
    this.tokenTicket = tokenTicket;
    this.name = name;
    this.surname = surname;
    this.telephone = telephone;
    this.email = email;
    this.cart = cart;
    this.additional_info = additional_info
    this.t = t;
  }

  async pay(): Promise<void> {
      
    let body = this.mountBody()
    let result = await getPaymentToken(JSON.stringify(body),this.publicToken, this.namePasarela);

    if(result.session) {

      let url;
      if(process.env.REACT_APP_ENVIRONMENT === 'dev'){
        url = `http://sandbox-checkoutform.greenpay.me/`
      } else if (process.env.REACT_APP_ENVIRONMENT === 'prod'){
        url = `https://checkout-form.greenpay.me/`
      }
      window.location.href = `${url}${result.session}`;

    } else if(result.length > 0){

      Sentry.captureException(new Error(`Greenpay - Error Crear orden pago - Parámetros incorrectos`));
      modalError(`${this.t("errorCreatePayment")} - 403`) // codigo 403. Los parámetros no tienen el formato correcto
   
    } else {

      let error = result.errors[0]
      let code;
      switch(error){
        case 'Error: Merchant doesn´t exist': code = 405;
                                      break;    
        case 'Error: Bank Entity doesn´t exist': code = 406;
                                          break;
        default: code = 400;
      }
      Sentry.captureException(new Error(`Greenpay - Error Crear orden pago ${error} ${code}`));
      modalError(`${this.t("errorCreatePayment")} - ${error} ${code}`)

    }

    return result
  }

  mountBody() {
    let url_callback;
    if(environment === 'prod'){
      url_callback = `https://${process.env.REACT_APP_SUBDOMINIO_PROD}/${this.publicToken}/payment/processing/${this.tokenTicket}`
    }else if(environment === 'dev'){
      url_callback = `https://${process.env.REACT_APP_SUBDOMINIO_DEV}/${this.publicToken}/payment/processing/${this.tokenTicket}`
    }

    let productos = []
    let tickets = [...this.cart.cartTickets,...this.cart.cartPacketTickets, ... this.cart.cartCollaboratorTickets, ...this.cart.cartCollaboratorPacketTickets]

    tickets.forEach(t => {
      let name;
      let total;
      let type;
      // COMPROBACION SI ISCARTTICKET, ISCArtPACKETTICKEt
      if (isCartTicket(t) || isCartCollaboratorTicket(t)) {
        name = t.ticket.stretchName
        total = t.ticket.visitas.total
        type = 'stretch';
      }else if (isCartPacketTicket(t) || isCartCollaboratorPacketTicket(t)) {
        name = t.packetTicket.packet.name
        total = t.packetTicket.visitas.total
        type = 'packet'
      }
    let aux = {
      description: name,
      skuId: t.numero,
      quantity: t.quantityReserved,
      price: total,
      type: type,
    }
    productos.push(aux)
    })

    let body = {
      url: environment === 'prod' ? 'https://merchant.greenpay.me/' : 'https://sandbox-merchant.greenpay.me/',
      totalAmount: this.totalAmount,
      money: this.money,
      tokenTicket: this.tokenTicket,
      url_callback: url_callback,
      name: this.name,
      surname: this.surname,
      email: this.email,
      cart: this.cart,
      productos: productos,
      additionalInformation: this.additional_info,
      telephone: this.telephone,
      additionalInfo: JSON.parse(this.additional_info).dataBooking.additionalInformation
    }
  
    return body;
  }

  greenPayResponse(id, res){
    //Add your logic
    if(res.status === 200){
      //Response is OK, someting payed
      console.log("payed", JSON.stringify(res))
    } else { 
      //An ERROR ocurred
      console.log("error", JSON.stringify(res))
    }
  }
}