import {BasePacket} from "models/Packet";
import Stretch from "models/Stretch"
import {Markup} from 'interweave';
import {useInitIdioma} from "customHooks/useInitIdioma";
import React from "react";
import './infoweb.css'

interface Props {
  trayecto: Stretch | BasePacket
  orden?: number
  publicToken: any
  language?: string
}

export const InfoWeb = ({trayecto, orden, publicToken, language}: Props) => {

  const {idioma: _idioma, t} = useInitIdioma(publicToken) // Custom Hook
  let idioma = language? language : _idioma
  const info = trayecto.dataweb && trayecto.dataweb.array ? trayecto.dataweb.array : null;
  
  const infoArray = [
    {title: `custom`, value: info && info.custom && info.custom[idioma] ? info.custom[idioma] : ''},
    {title: `${t("detailedDescription")}`, value: info && info.descripcion && info.descripcion[idioma] ? (info.descripcion[idioma]) : ''},
    {title: `${t("recommendations")}`, value: info && info.recomendaciones && info.recomendaciones[idioma] ? info.recomendaciones[idioma] : ''},
    {title: `${t("location")}`, value: info && info.localizacion && info.localizacion[idioma] ? info.localizacion[idioma] : ''},
    {title: `${t("nearbyServices")}`, value: info && info.serviciosCercanos && info.serviciosCercanos[idioma] ? info.serviciosCercanos[idioma] : ''},
    {title: `${t("requirements")}`, value: info && info.normas && info.normas[idioma] ? info.normas[idioma] : ''},
    {title: `${t("others")}`, value: info && info.otros && info.otros[idioma] ? info.otros[idioma] : ''},
    {title: `terms`, value: info && info.terminosCondiciones && info.terminosCondiciones[idioma] ? info.terminosCondiciones[idioma] : ''},
  ]

  let className = `tour-sidebar_description_${orden}`
  return (
    <div className={`details__content ${className}`}>
      {

        infoArray && infoArray.map((i, index) => {
          if (i.title === 'custom' && i.value !== '' && i.value) {
            return (
              <React.Fragment key={`custom_${index}`}>
                <Markup content={i.value} key={index}/>
                <br/>
              </React.Fragment>
            )
          }

          if (i.title === 'terms' && i.value !== '' && i.value) {
            return (
              <span key={`span_${i.value}`}>
                <Markup content={i.value} key={index}/>
              </span>
            )
          }
          return null
        })
      }
    </div>
  )
}