import {getAllData} from '../../Request/Request'

export const getOperationLine = async (operationLine_id: number, publicToken: string) => {
    try {
    const data = await getAllData(publicToken)
    if(data.statusCode === 401){     
        return {statusCode: data.statusCode}
    }
        return data.operationLines.find(operationLine => {
           return operationLine.id === operationLine_id})
    } catch (e) {
        console.log('error: ', e)
        throw e;
    }
}
