import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { BodyError } from './bodyError';
import { FooterView } from './FooterView';
import { HeaderView } from './HeaderView';

interface ErrorPageParams {
    public: string
}

type ErrorPageProps = RouteComponentProps<ErrorPageParams>;

export const CookiesWarning: React.FC<ErrorPageProps> = (props: ErrorPageProps) => {
    const publicToken = props.match.params.public;
    const [t,i18n] = useTranslation("global");
    const userLang = window.navigator.language;
    const userLanguage = userLang.substring(0,2);

    i18n.changeLanguage(userLanguage);  
    return(
        <div className="page-wrapper animated fadeIn">
            <HeaderView publicToken={publicToken}/>           
            <BodyError message={t("mainCookies")} listParagraph={[t("cookies1"), t("cookies2")]}/>
            <FooterView/>
        </div>
    );
}

