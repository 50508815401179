import ReactDOM from 'react-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import global_es from './translations/es/global.json';
import global_en from './translations/en/global.json';
import global_de from './translations/de/global.json';
import global_fr from './translations/fr/global.json';

import './styles/sass/style.scss'
import './styles/sass/style.react.scss'

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const {PUBLIC_URL, REACT_APP_ENVIRONMENT} = process.env

if (REACT_APP_ENVIRONMENT === 'prod'){
  Sentry.init({
    dsn: 'https://9b4ed4759faa4bfc9ace1b03c1e35623@o1411687.ingest.sentry.io/4504921177653248',
    integrations: [new BrowserTracing()],
    environment: REACT_APP_ENVIRONMENT === 'prod' ? 'production' : 'develop',

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const userLang = window.navigator.language;
let languageLocalStorage;
try {
  languageLocalStorage = localStorage.getItem('idioma')
} catch(e) {

}

const userLanguage = userLang.substring(0,2);

// TODO lista de idiomas a codigo duro
let idiomas = ['es','en'];

let defaultLocale = languageLocalStorage || userLang

if(!languageLocalStorage || !idiomas.includes(userLanguage)) {
  defaultLocale = 'es'
  try {
    localStorage.setItem('idioma', defaultLocale)
  } catch(e) {

  }
}

i18next.init({
  interpolation : {escapeValue: false},
  lng: defaultLocale,
  resources: {
    es: {
      global: global_es
    },
    en: { 
      global: global_en
    },
    de: {
      global: global_de
    },
    fr: {
      global: global_fr
    }
  }
});

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
      <App basename={PUBLIC_URL} />
  </I18nextProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
