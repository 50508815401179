import Extra from './Extra'

export interface Vis {
    names: string[],
    indices: number[],
    vis: number[],
    price: number[],
    priceNet?: number[],   
    extras: Extra[],
    extrasEdit?: any,
    base: number,
    total: number,
    totalNet?: number,
    disabledButtons: boolean
    discount: IDiscount
    totalDiscount: number
}

export interface IDiscount {
    discountCode: string
    typeDiscount: TypeDiscount
    value: number
}

export const addDiscount = ({vis, discount}: {vis:Vis, discount: IDiscount}) => {
    vis.discount = discount
    const tot = vis.total
    let totDiscount
    if (discount.typeDiscount === TypeDiscount.fix) {
        if (tot < discount.value) {
            totDiscount = tot
        } else {
            totDiscount = discount.value
        }
    } else if (discount.typeDiscount === TypeDiscount.percentage) {
        totDiscount = tot * discount.value / 100
    } else { // no debe entrar aquí
        throw new Error('tipo de descuento no válido')
    }
    vis.total = tot
    vis.totalDiscount = totDiscount
    return vis
}

export const resetDiscount = ({vis}: {vis: Vis}) => {
    vis.discount = null
    vis.totalDiscount = 0
    return vis
}

export const TypeDiscount = {
    fix: 'fix',
    percentage: 'percentage'
} 

export type TypeDiscount = string