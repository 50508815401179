import {Packet} from '../../models/Packet'
import {getAllData} from '../../Request/Request'
import OperationLine from 'models/OperationLine'

export const getPacketByID = async (operationLine: OperationLine, packet_id: number) => {
    const packet: Packet = operationLine.packets.find(packet => packet.id === packet_id)
    return new Promise<Packet>((resolve, reject) => {
        return resolve(packet)
    })
}

// TODO ahora el back los envía en endpoints, quizás sea mejor en una sola petición
// servicios en colaboración (son de otros operadores que puede vender)
// Control de errores hecho
export const getCollaboratorPacketsData = async (publicToken: string) => {
    try{
        const res = await getAllData(publicToken)
        if(res.statusCode === 401){
            return null;
        }
        if(res.statusCode >= 400){
            throw new Error()
        }
        return res.collaboratorPackets
    }catch(e){
        throw e;
    }
}

// Control de errores hecho
export const getCollaboratorPacketByID = async (packet_id: number, operator_id, publicToken: string) => {
    /*const collaboratorPacket: CollaboratorPacket = hardCodeCollaboratorPackets.find(collaboratorPacket => collaboratorPacket.id === packet_id)
    return new Promise<CollaboratorPacket>((resolve, reject) => {
      return resolve(collaboratorPacket)
    })*/
    return getCollaboratorPacketsData(publicToken).then((values: any) => {
        if(values === null){   
            return {statusCode: 401}          
        }
        
        const packet = values.find(value => value.id === packet_id && value.operator_id === operator_id)
        return packet
    }).catch(e =>{
        throw e;
    })
}

// Control de errores hecho
export const addPacketToCart = async(ticket, publicToken) => {

    const baseURLTicando = process.env.REACT_APP_baseURLTicando
    try{
        const request = await fetch(
            `${baseURLTicando}/tokenticket/addcart3`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "x-provider": "creaticket",
                    "Authorization": `Bearer ${publicToken}`
                },
                credentials: 'include',
                body: JSON.stringify(ticket)
            }
        )
        if(request.status >= 400){
            throw new Error()
        }
        return request.json()
    }catch(e){
        throw e;
    }

}
